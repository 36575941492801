import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import 'react-activity/dist/react-activity.css'
import TextareaAutosize from 'react-textarea-autosize'
import moment from 'moment'

import TagsComponent from './TagsComponent';
import MpkSplit from './MpkSplit/MpkSplit'
import Calendar from '../Calendar';

import parseDate from '../../helpers/dateParser'
import { waProjects } from '../../helpers/MpkSplit/wineAvenue';

import AvailableInOtherPlanLabel from '../AvailableInOtherPlanLabel'

import { FileActions } from '../../actions/FileActions';

import '../../css/OcrPage.css'
export class OcrPageExpensesForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    checkDate = (date) => {
        if (date && parseDate(date).match(new RegExp("[0-9]{2}-[0-9]{2}-[0-9]{4}", "i"))) {
            return true
        } else {
            return false
        }
    }

    render() {
        const {
            expensesOnDoc,
            currentProjectRole,
            tags, tagsList, tagsCreationLimitation,
            calcHeight, focusedTextArea, posUpDatePicker, posYDatePicker,
            dataConfirmCheckbox,
            user,
            mpkSplit,
            isAccountantInCurrentCompany, currentInvoiceImageData, documentDescription
        } = this.props

        console.log(tagsList)
        console.log('ESPENSEDOC', expensesOnDoc)
        return (
            <>
                <div className="by-hand-wrapper">
                    <div className="custome-simple-bar-wrapper" ref={this.props.setFormRef}>
                        <SimpleBar style={{ height: calcHeight, width: '100%', paddingRight: '10px' }}>
                            {
                                expensesOnDoc.map((expense, i) => {

                                    return (
                                        <div className="expense-block">
                                            <h2>{this.context.t('Expense bill')}{expensesOnDoc.length > 1 ? ` ${i + 1}` : ''}</h2>
                                            {
                                                i >= 1 ? (
                                                    <>
                                                        <span className="delete-expense-button" onClick={e => this.props.deleteExpenseBlock(e, i)}></span>
                                                    </>
                                                ) : (
                                                    null
                                                )
                                            }
                                            <div className="expense-prop doc-number">
                                                <label>{this.context.t('Document number')}</label>
                                                <TextareaAutosize
                                                    name='docNumber'
                                                    className={`docNumber ${expense.docNumber ? '' : 'no-content'}`}
                                                    value={expense.docNumber}
                                                    onChange={(e) => this.props.onInputChangeExpense(e, i)}
                                                    onClick={(e) => this.props.scrollToAreaForExpenses(e, expense.docNumberPossition, i, 'docNumber')}
                                                    onBlur={e => this.props.focusOutAreaForExpenses(e)}
                                                />
                                            </div>
                                            <div className='expense-prop issueDate'>
                                                <label>{this.context.t('Issue Date')}</label>
                                                <TextareaAutosize
                                                    className={`issueDate ${expense.issueDate ? '' : 'no-content'}`}
                                                    name='issueDate'
                                                    value={expense.issueDate}
                                                    onChange={(e) => this.props.onInputChangeExpense(e, i)}
                                                    onMouseDown={e => this.props.showDatePickerExpenses(e, i)}
                                                    onClick={(e) => this.props.scrollToAreaForExpenses(e, expense.issueDatePossition, i, 'issueDate')}
                                                    onBlur={e => this.props.focusOutAreaForExpenses(e)}
                                                />
                                                <span className="calendar-icon" onClick={(e) => this.props.showDatePickerExpenses(e, i)}></span>
                                                {
                                                    focusedTextArea === `issueDate${i}` ? (
                                                        <div className={`date-picker ${posUpDatePicker ? 'up' : ''}`} style={{ top: posYDatePicker }}>
                                                            <Calendar
                                                                selectDay={this.props.onCalendarSelect}
                                                                unselectDay={this.props.onCalendarUnselecet}
                                                                selectedDay={
                                                                    this.checkDate(expense.issueDate) ?
                                                                        {
                                                                            display: moment(parseDate(expense.issueDate), 'DD-MM-YYYY').format('LL'),
                                                                            value: moment(parseDate(expense.issueDate), 'DD-MM-YYYY')
                                                                        } : {
                                                                            display: null,
                                                                            value: null
                                                                        }
                                                                }
                                                                projectId={'none'}
                                                                startDate={this.checkDate(expense.issueDate) ? moment(parseDate(expense.issueDate), 'DD-MM-YYYY') : moment()}
                                                            />
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>

                                            <div className="expense-prop price">
                                                <label>{this.context.t('Price')}</label>
                                                <TextareaAutosize
                                                    name='price'
                                                    className={`price ${expense.price ? '' : 'no-content'}`}
                                                    value={expense.price}
                                                    onChange={(e) => this.props.onInputChangeExpense(e, i)}
                                                    onClick={(e) => this.props.scrollToAreaForExpenses(e, expense.pricePossition, i, 'price')}
                                                    onBlur={e => this.props.focusOutAreaForExpenses(e)}
                                                />
                                            </div>
                                            <div className="expense-prop comment">
                                                <label>{this.context.t('Comment')}</label>
                                                <TextareaAutosize
                                                    name='comment'
                                                    className={`comment`}
                                                    value={expense.comment}
                                                    onChange={(e) => this.props.onInputChangeExpense(e, i)}
                                                    onBlur={e => this.props.focusOutAreaForExpenses(e)}
                                                />
                                            </div>

                                            <TagsComponent
                                                currentTags={expense.tags}
                                                tagsList={tagsList}
                                                canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                                                setTags={value => this.props.onInputChangeExpense({ target: { name: 'tags', value: value } }, i)}
                                                maxHeight={expensesOnDoc && expensesOnDoc.length === 1 ? 170 : 300}
                                            />

                                            {
                                                currentInvoiceImageData?.docflow?.has_mpk ? (
                                                    <MpkSplit
                                                        fileId={currentInvoiceImageData?.dbId}
                                                        ftvId={currentInvoiceImageData?.ftvId}
                                                        projectId={currentInvoiceImageData?.projectId}
                                                        totalNetAmount={expense.price}
                                                        mpkSplit={mpkSplit}
                                                        setMpkSplit={this.props.setMpkSplit}
                                                        isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                                        companyId={this.props.companyId}
                                                        currency={'PLN'}
                                                        paid={false}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className={`ocr-data`}>
                                <label>{this.context.t('Document description')}</label>
                                <TextareaAutosize
                                    className={``}
                                    value={documentDescription}
                                    name='doc_description'
                                    onChange={(e) => this.props.setDocComment(e)}
                                    maxRows={10}
                                    onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', documentDescription, '')}
                                />
                            </div>
                        </SimpleBar>
                    </div>

                    <div className="action-buttons" ref={this.props.setButtonsRef}>
                        {
                            currentProjectRole !== 'USER1' ? (
                                <div className="checkmark" onClick={() => this.props.confirmData()}>
                                    {this.context.t('I confirm the compliance of the data in the form with the data on the original document')}
                                    <div className={`checkbox ${dataConfirmCheckbox ? 'checked' : ''}`}></div>
                                </div>
                            ) : (
                                null
                            )
                        }
                        <button type="button" className={`button`} onClick={() => this.props.saveData()}>
                            {
                                currentInvoiceImageData.userCanVerifyAndAccept ?
                                    this.context.t("Accept and Save")
                                    :
                                    currentInvoiceImageData.userAlreadyVerified ?
                                        this.context.t("Save")
                                        :
                                        this.context.t("Send for approval")

                            }
                        </button>

                        {
                            currentInvoiceImageData?.docflow?.has_mpk ? (
                                <span className="switcher" onClick={(e) => this.props.addExpenseToDoc(e)}>{this.context.t('Add expense to document')}</span>
                            ) : null
                        }
                    </div>
                </div>
            </>
        )
    }
}

OcrPageExpensesForm.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userCompanies: state.Company.companies,
})

const mapDispatchToProps = {
    autoSaveOcr: FileActions.autoSaveOcr,
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageExpensesForm)