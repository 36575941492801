import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import PropTypes from 'prop-types'
import moment from 'moment'
import copy from 'copy-to-clipboard';

import QrCodesForMobile from './QrCodesForMobile'

import { FileActions } from '../../actions/FileActions'
import { AlertActions } from '../../actions/AlertActions'
import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'
import { AvailableTabs, ActionsTabsComponents, ActionsHistoryTabsComponents, SettingsTabsComponents, SuperadminTabsComponents } from "../../reducers/V2_TabsReducer"

export class PendingFilesList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showMobilePopup: false
        }
    }

    drawIcon = (name, id) => {

        var extension = name.split('.')
        extension = extension[extension.length - 1]

        if (extension === 'pdf' || extension === 'PDF') {
            return <div id={`fileRowIcon${id}`} className="icon pdf-icon"></div>
        } else {
            return <div id={`fileRowIcon${id}`} className="icon image-icon"></div>
        }
    }

    render() {
        const { showMobilePopup } = this.state
        const { connectDropTarget, hovered, containedItems, dropTargetId, simpleBarHeightWithDocs, simpleBarHeightWithoutDocs, accessLevel, viewMode, projectCode } = this.props
        return (
            <div className={`drop-file-target ${hovered ? 'target-hovered' : ''} ${containedItems.files.length > 0 && containedItems.loaded ? '' : 'no-files'}`}>
                <div className={`corner-borders ${containedItems.files.length > 0 && containedItems.loaded ? 'show' : 'hide'}`}>
                    <div className="top-left"></div>
                    <div className="top-right"></div>
                    <div className="bottom-left"></div>
                    <div className="bottom-right"></div>
                </div>
                <SimpleBar style={{ zIndex: '2', height: '200px', minHeight: '80px', width: '100%', paddingRight: '5px' }}>
                    {
                        containedItems.files.length > 0 && containedItems.loaded ? (
                            containedItems.files.map((file, i) => {
                                if (file.projectId === dropTargetId && this.props.fileMatchesFilter(file)) {
                                    if (file.imageData !== "processing") {
                                        return (
                                            <div id={`fileRowContainer${file.ftvId}`} className={`drag-file-item`}>
                                                <div
                                                    id={`fileRowLink${file.ftvId}`}
                                                    className="link crop-link"
                                                    onClick={() => this.props.openNewTab(AvailableTabs.OCR, AvailableTabs.OCR, { projectId: file.projectId, fileId: file.dbId, fileName: file.name })}
                                                >
                                                    <div id={`fileRow${file.ftvId}`} className="file-row" key={i}>
                                                        <div id={`fileRowName${file.ftvId}`} className={`name ${file.priority === 'high' && viewMode === 'MULTI' ? 'red' : ''}`}>
                                                            <div id={`fileRowBg${file.ftvId}`} className="dropzone-white-bg"></div>
                                                            {this.drawIcon(file.name, file.ftvId)}
                                                            <span id={`fileRowDate${file.ftvId}`} className="date">{moment(file.uploadDate).format('DD MMM HH:mm')}</span>
                                                            {file.name}
                                                            {/* <div id={`fileRowUploadMethod${file.ftvId}`} className={`upload-method ${file.uploadMethod}`}></div> */}
                                                            {file.priority === "high" && viewMode !== 'MULTI' &&
                                                                <>
                                                                    <div id={`fileRowPriority${file.ftvId}`} className="priority-icon" data-for={`doc-priority-${accessLevel}-${i}`}>
                                                                        {this.context.t('URGENT')}
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                file.status === 'NOT_VERIFIED' ? (
                                                                    <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ver ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Verify')}</div>

                                                                ) : (
                                                                    file.status === "NEW" ? (
                                                                        <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ver ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Verify')}</div>
                                                                    ) : (
                                                                        file.status === 'IN_PROGRESS' ? (
                                                                            accessLevel === 'USER1' ? (
                                                                                <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ver acc ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Verified')}</div>
                                                                            ) : (
                                                                                <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Accept')}</div>
                                                                            )
                                                                        ) : (
                                                                            <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Processing...')}</div>
                                                                        )
                                                                    )
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div id={`fileRow${file.ftvId}`} className="file-row" key={i}>
                                                <div id={`fileRowName${file.ftvId}`} className="name">
                                                    <div id={`fileRowBg${file.ftvId}`} className="dropzone-white-bg"></div>
                                                    {this.drawIcon(file.name, file.id)}
                                                    <span id={`fileRowDate${file.ftvId}`} className="date">{moment(file.uploadDate).format('DD MMM HH:mm')}</span>
                                                    {file.name}
                                                    {/* <div id={`fileRowUploadMethod${file.ftvId}`} className={`upload-method ${file.uploadMethod}`}></div> */}

                                                    <div id={`fileRowStatus${file.ftvId}`} className={`status processing`}>
                                                        <Spinner size={10} speed={0.8} color={"#9099A5"} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            })
                        ) : (
                            <div className={`new-dropzone-indicator ${viewMode === 'MULTI' ? 'small' : ''}`} style={{ height: simpleBarHeightWithoutDocs }}>
                                <div className="button-quarter dnd">
                                    <div className="dropzone-button">
                                        <div className="icon dnd"></div>
                                        <p>{this.context.t("Drop your documents here")}</p>
                                    </div>
                                </div>
                                <div className="button-quarter upload">
                                    <div className="dropzone-button">
                                        <div className="icon upload"></div>
                                        <p>{this.context.t("Attach a document")}</p>
                                    </div>
                                </div>
                                <div className="button-quarter email">
                                    <div data-tip="show" data-for={`binderEmailTooltip-${projectCode}`} className="dropzone-button" id={`fileRowEmailAddressButton-${projectCode}`} onClick={e => { copy(`${projectCode.toLowerCase()}@app.easydocs.pl`); this.props.showInfo('Copied to clipboard') }}>
                                        <div className="icon email" id={`fileRowEmailAddressIcon-${projectCode}`}></div>
                                        <p id={`fileRowEmailAddressText-${projectCode}`}>
                                            {this.context.t("Send documents by e-mail to ")}
                                            <a id={`fileRowEmailAddressLink-${projectCode}`} href={`mailto:${projectCode.toLowerCase()}@app.easydocs.pl`}>{`${projectCode.toLowerCase()}@app.easydocs.pl`}</a>
                                        </p>
                                    </div>

                                    <ReactTooltip id={`binderEmailTooltip-${projectCode}`} place="top" effect="solid" className="default-tooltip">
                                        <p>{projectCode.toLowerCase()}@app.easydocs.pl</p>
                                    </ReactTooltip>
                                </div>
                                <div className="button-quarter mobile" onClick={() => this.setState({ showMobilePopup: true })}>
                                    <div className="dropzone-button" id={`fileRowMobileButton-${projectCode}`}>
                                        <div className="icon mobile" id={`fileRowMobileIcon-${projectCode}`}></div>
                                        <p id={`fileRowMobileTest-${projectCode}`}>{this.context.t("Send documents using the mobile application")}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </SimpleBar>


                {
                    containedItems.files.length > 0 && containedItems.files.length < 4 && containedItems.loaded ? (
                        <div className={`new-dropzone-indicator with-files absolute  ${viewMode === 'MULTI' ? 'small' : ''}`}>
                            <div className="button-quarter dnd">
                                <div className="dropzone-button">
                                    <div className="icon dnd"></div>
                                </div>
                            </div>
                            <div className="button-quarter upload">
                                <div className="dropzone-button">
                                    <div className="icon upload"></div>
                                </div>
                            </div>
                            <div className="button-quarter email">
                                <div data-tip="show" data-for={`binderEmailTooltip-${projectCode}`} className="dropzone-button" id={`fileRowEmailAddressButton-${projectCode}`} onClick={e => { copy(`${projectCode.toLowerCase()}@app.easydocs.pl`); this.props.showInfo('Copied to clipboard') }}>
                                    <div className="icon email" id={`fileRowEmailAddressIcon-${projectCode}`}></div>
                                </div>

                                <ReactTooltip id={`binderEmailTooltip-${projectCode}`} place="top" effect="solid" className="default-tooltip">
                                    <p>{projectCode.toLowerCase()}@app.easydocs.pl</p>
                                </ReactTooltip>
                            </div>
                            <div className="button-quarter mobile" onClick={() => this.setState({ showMobilePopup: true })}>
                                <div className="dropzone-button" id={`fileRowMobileButton-${projectCode}`}>
                                    <div className="icon mobile" id={`fileRowMobileIcon-${projectCode}`}></div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }

                {
                    showMobilePopup ? (
                        <QrCodesForMobile closePopup={() => this.setState({ showMobilePopup: false })} />
                    ) : null
                }
            </div>
        )
    }
}

PendingFilesList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    acceptedFirtsDocument: state.User.acceptedFirtsDocument,
    viewMode: state.User.dashboardViewModel,
    filesToVerifiLoaded: JSON.parse(JSON.stringify(state.File.filesToVerifiLoaded)),
})

const mapDispatchToProps = {
    alertInfo: AlertActions.info,
    openNewTab: TabsControlerActions.openNewTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingFilesList)