import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

import CostsTabletooltips from '../ReportsPageComponents/CostsTabletooltips'

import _ from "lodash";

import Dotdotdot from 'react-dotdotdot'

import { ReportsActions } from '../../actions/ReportsActions'
import countryCodeEU from '../../helpers/countryCodeEU.json'

export class CostsTable extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            docsList: [],
            viesCheckedIndex: [],
            vatIdToCheckedInVies: []
        }
    }

    componentDidMount() {
        const docList = this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e))
        this.setState({
            docsList: docList,
            docsSortField: this.props.sortField,
            docsSortOrder: this.props.sortOrder
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.docsDownloadedForRaport !== this.props.docsDownloadedForRaport || this.filtersDidChange(prevProps)) {
            const docList = this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e))
            this.setState({
                docsList: docList
            })
        }
        ReactTooltip.rebuild()

    }

    filtersDidChange = prevProps => {
        const { searchField, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted } = this.props

        if (searchField !== prevProps.searchField) return true
        if (searchCurrency !== prevProps.searchCurrency) return true
        if (selectOnlyAvailableForReport !== prevProps.selectOnlyAvailableForReport) return true
        if (selectOnlyAvailableForBankTransferFile !== prevProps.selectOnlyAvailableForBankTransferFile) return true
        if (selectOnlyNotPaid !== prevProps.selectOnlyNotPaid) return true
        if (selectOnlyNotAccounted !== prevProps.selectOnlyNotAccounted) return true

        return false
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return ''
        }
    }

    getSortClass = (sortFieldCheck) => {
        const { sortField, sortOrder } = this.props

        if (sortField === sortFieldCheck) {
            if (sortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    headerRendererCosts = (name) => {
        switch (name) {
            case 'all':
                const { allDocsSelected } = this.props
                return (
                    <div className="wrapper all">
                        <span className="column-name" onClick={() => this.props.selectAll()}>
                            {
                                allDocsSelected ? this.context.t('Unselect all ') : this.context.t('Select all ')
                            }
                        </span>
                    </div>
                )
            case 'fileIcon':
                return (
                    <div className="wrapper">
                    </div>
                )
            case 'docType':
                return (
                    <div className="wrapper counterparty" onClick={() => this.props.switchSortingFilter('docType')}>
                        <span className="column-name">{this.context.t('Document type')}</span>
                        <span className={this.getSortClass('docType')}></span>
                    </div>
                )
            case 'counterparty':
                return (
                    <div className="wrapper counterparty" onClick={() => this.props.switchSortingFilter('company')}>
                        <span className="column-name">{this.context.t('Counterparty')}</span>
                        <span className={this.getSortClass('company')}></span>
                    </div>
                )
            case 'documentNumber':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('documentNumber')}>
                        <span className="column-name">{this.context.t('Document number')}</span>
                        <span className={this.getSortClass('documentNumber')}></span>
                    </div>
                )
            case 'importDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('importDate')}>
                        <span className="column-name">{this.context.t('Import Date')}</span>
                        <span className={this.getSortClass('importDate')}></span>
                    </div>
                )
            case 'issueDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('issueDate')}>
                        <span className="column-name">{this.context.t('Issue Date')}</span>
                        <span className={this.getSortClass('issueDate')}></span>
                    </div>
                )
            case 'dueDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('dueDate')}>
                        <span className="column-name">{this.context.t('Due Date')}</span>
                        <span className={this.getSortClass('dueDate')}></span>
                    </div>
                )
            case 'nettoNumber':
                return (
                    <div className="wrapper price" onClick={() => this.props.switchSortingFilter('nettoNumber')}>
                        <span className="column-name">{this.context.t('Net')}</span>
                        <span className={this.getSortClass('nettoNumber')}></span>
                    </div>
                )
            case 'priceNumber':
                return (
                    <div className="wrapper price" onClick={() => this.props.switchSortingFilter('priceNumber')}>
                        <span className="column-name">{this.context.t('Amount to pay')}</span>
                        <span className={this.getSortClass('priceNumber')}></span>
                    </div>
                )
            case 'currency':
                return (
                    <div className="wrapper center" onClick={() => this.props.switchSortingFilter('currency')}>
                        <span className="column-name">{this.context.t('Currency')}</span>
                        <span className={this.getSortClass('currency')}></span>
                    </div>
                )
            case 'comment':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('comment')}>
                        <span className="column-name">{this.context.t('Comment')}</span>
                        <span className={this.getSortClass('comment')}></span>
                    </div>
                )
            case 'tags':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('tags')}>
                        <span className="column-name">{this.context.t('Tags')}</span>
                        <span className={this.getSortClass('tags')}></span>
                    </div>
                )
            case 'alreadyInReport':
                return (
                    <div className="wrapper in-report center" onClick={() => this.props.switchSortingFilter('alreadyInReport')}>
                        <span className="column-name">{this.context.t('In report')}</span>
                        <span className={this.getSortClass('alreadyInReport')}></span>
                    </div>
                )
            case 'splitPayment':
                return (
                    <div className="wrapper already-paid center" onClick={() => this.props.switchSortingFilter('splitPayment')}>
                        <span className="column-name">{this.context.t('Split Payment')}</span>
                        <span className={this.getSortClass('splitPayment')}></span>
                    </div>
                )
            case 'alreadyPaid':
                return (
                    <div className="wrapper already-paid center" onClick={() => this.props.switchSortingFilter('alreadyPaid')}>
                        <span className="column-name">{this.context.t('Paid?')}</span>
                        <span className={this.getSortClass('alreadyPaid')}></span>
                    </div>
                )
            case 'accounted':
                return (
                    <div className="wrapper already-paid center" onClick={() => this.props.switchSortingFilter('accounted')}>
                        <span className="column-name">{this.context.t('Accounted?')}</span>
                        <span className={this.getSortClass('accounted')}></span>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnWidth = name => {
        switch (name) {
            case 'all':
                return '4%'
            case 'docType':
                return '8%'
            case 'fileIcon':
                return '1%'
            case 'counterparty':
                return '12%'
            case 'documentNumber':
                return '7%'
            case 'importDate':
                return '5%'
            case 'issueDate':
                return '5%'
            case 'dueDate':
                return '5%'
            case 'nettoNumber':
                return '6%'
            case 'priceNumber':
                return '6%'
            case 'currency':
                return '4.5%'
            case 'comment':
                return '8.5%'
            case 'tags':
                return '7%'
            case 'alreadyInReport':
                return '4%'
            case 'splitPayment':
                return '4%'
            case 'alreadyPaid':
                return '4%'
            case 'accounted':
                return '5%'
        }
    }

    sort = ({ sortBy, sortDirection }) => {
        var { docsSortField, docsSortOrder, docsList } = this.state
        if (docsSortField === sortBy) {
            if (docsSortOrder === 'asc') {
                docsSortOrder = 'desc'
            } else {
                docsSortOrder = 'asc'
            }
        } else {
            docsSortField = sortBy
            docsSortOrder = 'asc'
        }
        docsList.sort(this.dynamicComparer(docsSortField, docsSortOrder))

        this.setState({
            docsList: docsList,
            docsSortField: sortBy,
            docsSortOrder: docsSortOrder
        })
    }

    checkIfDocMatchesFilterOptions = doc => {
        const { searchField, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted, alreadyPaidDocs, downloadedDocsType } = this.props

        if (doc) {
            var counterparty = doc.counterparty ? doc.counterparty.toLowerCase() : ''
            var comment = doc.comment ? doc.comment.toLowerCase() : ''
            var tags = doc.tags ? doc.tags.toLowerCase() : ''
            var docNumber = doc.documentNumber ? doc.documentNumber.toLowerCase() : ''

            var matches = true

            if (searchField) {
                if (!(counterparty.includes(searchField.toLowerCase()) || comment.includes(searchField.toLowerCase()) || tags.includes(searchField.toLowerCase()) || docNumber.includes(searchField.toLowerCase()))) {
                    return false
                }
            }

            return matches
        } else {
            return false
        }
    }

    dynamicComparer = (key, order = 'asc') => {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (!varA) {
                comparison = -1
            } else if (!varB) {
                comparison = 1
            } else if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    rowRendererCosts = (doc, index) => {
        const { docsSelectedForReport, selectFor, alreadyPaidDocs, roleInSelectedProject } = this.props
        return (
            <div
                key={`table-row-${doc.id}`}
                className={`${index % 2 === 0 ? 'even' : 'odd'} ReactVirtualized__Table__row`}
                role="row"
            >
                {
                    <>
                        <div
                            className="ReactVirtualized__Table__rowColumn checkmarks"
                            style={{ flex: `0 1 4%` }}
                        >
                            {
                                doc.reportName !== 'PENDING' && doc.bankTransferFileName !== 'PENDING' && doc.waproXMLFileName !== 'PENDING' && doc.zipId !== 'PENDING' ? (
                                    <span
                                        className={`checkbox ${docsSelectedForReport.find(e => e.id === doc.id) ? 'checked' : ''} ${doc.wasExported ? 'inactive' : ''}`}
                                        onClick={e => this.props.addDocToSelected(e, doc)}>
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <Spinner size={11} speed={0.8} color={"#69B792"} />
                                    </span>
                                )
                            }
                        </div>
                        <div
                            className="ReactVirtualized__Table__rowColumn docType"
                            style={{ overflow: "hidden", flex: `0 1 8%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{this.context.t(doc.docTypeName)}</p></Dotdotdot></div>
                        <div
                            className={`ReactVirtualized__Table__rowColumn ${doc.docType === 'PAYMENT_ORDER' ? "payment-order-icon" : "file-icon"}`}
                            style={{ overflow: "hidden", flex: `0 1 2%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn counterparty"
                            style={{ overflow: "hidden", flex: `0 1 12%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{doc.counterparty}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn doc-number"
                            style={{ overflow: "hidden", flex: `0 1 7%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{doc.documentNumber}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn date"
                            style={{ overflow: "hidden", flex: `0 1 5%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{doc.importDate}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn date"
                            style={{ overflow: "hidden", flex: `0 1 5%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{doc.issueDate}</p></Dotdotdot></div>
                        <div
                            className={`ReactVirtualized__Table__rowColumn date ${this.getDueDateCellClass(doc)}`}
                            style={{ overflow: "hidden", flex: `0 1 5%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{moment(doc.dueDate).isValid() ? moment(doc.dueDate).format('YYYY-MM-DD') : doc.dueDate}</p></Dotdotdot></div>
                        <div
                            className={`ReactVirtualized__Table__rowColumn price ${parseFloat(doc.netto) < 0 ? 'refund' : ''}`}
                            style={{ overflow: "hidden", flex: `0 1 6%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{this.formatPrice(parseFloat(doc.netto))}</p></Dotdotdot></div>
                        <div
                            className={`ReactVirtualized__Table__rowColumn price ${parseFloat(doc.price) < 0 ? 'refund' : ''}`}
                            style={{ overflow: "hidden", flex: `0 1 6%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{this.formatPrice(parseFloat(doc.price))}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn currency"
                            style={{ overflow: "hidden", flex: `0 1 4.5%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{doc.currency}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn comment"
                            style={{ overflow: "hidden", flex: `0 1 8.5%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{doc.comment}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn tags"
                            style={{ overflow: "hidden", flex: `0 1 7%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        ><Dotdotdot clamp={2}><p>{doc.tags}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn in-report"
                            style={{ overflow: "hidden", flex: `0 1 4%` }}
                        >
                            {
                                doc.alreadyInReport && doc.reportName && doc.reportName !== 'PENDING' ? (
                                    <span className="icon-xlsx" onClick={e => this.props.downloadReport(e, doc.projectId, doc.reportName)}></span>
                                ) : (
                                    this.context.t("No")
                                )
                            }
                        </div>
                        <div
                            className="ReactVirtualized__Table__rowColumn mpp"
                            style={{ overflow: "hidden", flex: `0 1 4%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.filePath, doc.id, doc.docType, doc.fileId, doc.alreadyPaid)}
                        >
                            {doc.splitPayment ? this.context.t('Yes') : this.context.t('No')}
                        </div>
                        <div
                            className="ReactVirtualized__Table__rowColumn checkmarks"
                            style={{ overflow: "hidden", flex: `0 1 4%` }}
                        >
                            {
                                ['COSTS', 'INCOME'].includes(doc.docType) ? (
                                    alreadyPaidDocs.find(e => e === doc.id) || doc.alreadyPaid ? (
                                        <div className="payment-status" data-for={`payment-method-tooltip-${doc.paymentMethod}`} data-tip="show">
                                            <span className={`checkbox checked ${alreadyPaidDocs.find(e => e === doc.id) ? 'new' : ''}`} onClick={e => this.props.togglePaymentList(e, doc)}></span>
                                            <span>{doc.paymentMethod === 'TRANSFER' ? (
                                                this.context.t('P')
                                            ) : (
                                                doc.paymentMethod === 'CARD' ? (
                                                    this.context.t('K')
                                                ) : (
                                                    doc.paymentMethod === 'CASH' ? (
                                                        this.context.t('G')
                                                    ) : (
                                                        this.context.t('I')
                                                    )
                                                )
                                            )}</span>
                                        </div>
                                    ) : (
                                        <span className={`checkbox ${!doc.canPay ? 'inactive' : ''}`} onClick={e => this.props.togglePaymentList(e, doc)}></span>
                                    )
                                ) : (
                                    <span className={`checkbox inactive`}></span>
                                )
                            }
                        </div>
                        <div
                            className="ReactVirtualized__Table__rowColumn checkmarks"
                            style={{ overflow: "hidden", flex: `0 1 5%` }}
                        >
                            {
                                ['COSTS', 'INCOME'].includes(doc.docType) ? (
                                    <span className={`checkbox ${doc.accounted ? 'checked' : ''} ${roleInSelectedProject === 'USER1' ? 'inactive' : ''}`} onClick={e => this.props.toggleAccountedStatus(doc.docId, doc.id, doc.projectId, doc.docType, !doc.accounted)}></span>
                                ) : (
                                    <span className={`checkbox inactive`}></span>
                                )
                            }
                        </div>
                    </>
                }
            </div >
        )
    }

    getDueDateCellClass = doc => {
        const { alreadyPaidDocs } = this.props
        if (alreadyPaidDocs.find(e => e === doc.id) || doc.alreadyPaid || !doc.dueDate || !moment(doc.dueDate).isValid()) return ''
        if (moment(doc.dueDate).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') || doc.priority) return 'overdue'
        if (moment(doc.dueDate).format('YYYY-MM-DD') < moment().add(3, 'day').format('YYYY-MM-DD') && moment(doc.dueDate).format('YYYY-MM-DD') >= moment().add(1, 'day').format('YYYY-MM-DD')) return 'upcoming'
    }

    _setRef = windowScroller => {
        this._windowScroller = windowScroller;
    };


    render() {
        const { docsDownloadedForRaport, selectFor, tableHeight } = this.props
        const { docsSortField, docsSortOrder, docsList } = this.state

        return (
            <div className="table-responsive" ref={this.props.setTableRef}>
                <CostsTabletooltips selectFor={selectFor} />

                <div className='ReactVirtualized__Table'>
                    <div className="ReactVirtualized__Table__headerRow">
                        {
                            ['all', 'docType', 'fileIcon', 'counterparty', 'documentNumber', 'importDate', 'issueDate', 'dueDate', 'nettoNumber', 'priceNumber', 'currency', 'comment', 'tags', 'alreadyInReport', 'splitPayment', 'alreadyPaid', 'accounted'].map(column => {
                                return (
                                    <div className="ReactVirtualized__Table__headerColumn" style={{ flex: `0 1 ${this.getColumnWidth(column)}` }}>
                                        {this.headerRendererCosts(column)}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="ReactVirtualized__Table__Grid">
                        {
                            docsList.map((doc, index) => {
                                return (
                                    this.rowRendererCosts(doc, index)
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )

    }
}

CostsTable.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    docsDownloadedForRaport: state.Reports.docsDownloadedForRaport,
    alreadyPaidDocs: state.Reports.alreadyPaidDocs,
    sortField: state.Reports.docsSortField,
    sortOrder: state.Reports.docsSortOrder,
    downloadedDocsType: state.Reports.downloadedDocsType,
})

const mapDispatchToProps = {
    switchSortingFilter: ReportsActions.switchDocsSortingFilter,
    checkVatIdInVies: ReportsActions.checkVatIdInVies
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CostsTable))