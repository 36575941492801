import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

import { AlertActions } from '../../actions/AlertActions'

export class DocflowNotificationEditWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            itemName: '',
            editingItemName: false,
            selectedUsers: [],
            showUsersList: false,
            itemUsersCount: 1,
            itemDescription: '',
            emailNotification: false,
            webNotification: false,
            mobileNotification: false,
        }

        this.settingsWindowWrapperRef = React.createRef()
        this.usersWrapperRef = React.createRef()
        this.deadlineWrapperRef = React.createRef()
        this.requiredFieldsWrapperRef = React.createRef()
    }

    componentDidMount() {
        const { editedDocflowItem } = this.props
        this.setState({
            ...editedDocflowItem,
            itemName: this.context.t(editedDocflowItem.title),
        })

        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = event => {
        if (this.state.showUsersList && this.usersWrapperRef && !this.usersWrapperRef.current.contains(event.target)) {
            this.setState({
                showUsersList: false
            })
        }
    }

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target

        this.setState({ [name]: value })
    }

    toggleUsersList = (event) => {
        const clickedElement = event?.target || {};
        const clickedElementClass = clickedElement?.className || '';

        if (clickedElementClass.includes('remove')) {
            console.log('Remove button was clicked');
        } else {
            const { showUsersList } = this.state
            this.setState({
                showUsersList: !showUsersList
            })
        }
    }

    selectDocflowUser = user => {
        const { selectedUsers } = this.state
        var tempSelectedUsers = [...selectedUsers]

        if (tempSelectedUsers.find(u => u.id === user.id)) {
            tempSelectedUsers = tempSelectedUsers.filter(u => u.id !== user.id)
        } else {
            tempSelectedUsers.push(user)
        }


        this.setState({
            selectedUsers: tempSelectedUsers
        })
    }

    removeSelectedUser = user => {
        const { selectedUsers } = this.state
        var tempSelectedUsers = [...selectedUsers]

        tempSelectedUsers = tempSelectedUsers.filter(u => u.id !== user.id)

        this.setState({
            selectedUsers: tempSelectedUsers,
            showUsersList: false
        })
    }

    saveItem = () => {
        const {
            itemName, selectedUsers, itemDescription,
            emailNotification, webNotification, mobileNotification,
        } = this.state
        const { editedDocflowItem } = this.props
        var isValid = true

        if (selectedUsers.length === 0) {
            isValid = false
            this.props.alertWarn('Add users to be notified')
        }

        if (!itemDescription) {
            isValid = false
            this.props.alertWarn('Message is required')
        }

        if (!emailNotification && !webNotification && !mobileNotification) {
            isValid = false
            this.props.alertWarn('Select at least one notification method')
        }


        if (isValid) {
            var tempDocflowItem = { ...editedDocflowItem }
            tempDocflowItem.title = itemName
            tempDocflowItem.selectedUsers = selectedUsers
            tempDocflowItem.itemDescription = itemDescription
            tempDocflowItem.emailNotification = emailNotification
            tempDocflowItem.webNotification = webNotification
            tempDocflowItem.mobileNotification = mobileNotification

            this.props.save(tempDocflowItem)
        }
    }

    render() {
        const { availableDocflowUsers, editedDocflowItem } = this.props
        const {
            itemName, selectedUsers, itemDescription,
            emailNotification, webNotification, mobileNotification,
            editingItemName, showUsersList,
        } = this.state

        return (
            <div className="section-settings-window">
                <div className="settings-wrapper docflow" ref={this.settingsWindowWrapperRef}>
                    <h4>
                        {
                            editingItemName ? (
                                <>
                                    <input type="text" name="itemName" value={itemName} onChange={this.onInputChange} autoComplete="off" />
                                    <div className="icon accept-button" onClick={() => this.setState({ editingItemName: false })}></div>
                                </>
                            ) : (
                                <>
                                    <div>{itemName}</div>
                                    <div className="icon edit-button" onClick={() => this.setState({ editingItemName: true })}></div>
                                </>
                            )
                        }
                    </h4>
                    <h6>{this.context.t('Notification settings')}</h6>
                    <div className="settings-section stage-settings">
                        <div className={`form-group ${showUsersList ? 'in-front' : ''}`} ref={this.usersWrapperRef}>
                            <div className="selected-elements" onClick={this.toggleUsersList}>
                                {
                                    selectedUsers.map(user => {
                                        return <div className="element">
                                            {user.name}
                                            <span className="remove" onClick={() => this.removeSelectedUser(user)}></span>
                                        </div>
                                    })
                                }
                            </div>
                            <label htmlFor="" onClick={this.toggleUsersList}>{this.context.t('Users/s responsible for the stage')}</label>
                            <span className={`expend-icon ${showUsersList ? 'expended' : ''}`} onClick={this.toggleUsersList}></span>

                            {
                                showUsersList ? (
                                    <ul>
                                        <li className={`${selectedUsers.includes(u => u.id === 0) ? 'selected' : ''}`} onClick={() => this.selectDocflowUser({
                                            id: 0,
                                            name: this.context.t('User who added the document')
                                        })}>
                                            {this.context.t('User who added the document')}
                                        </li>
                                        {
                                            availableDocflowUsers.map(user => {
                                                return (
                                                    <li className={`${selectedUsers.includes(u => u.id === user.id) ? 'selected' : ''}`} onClick={() => this.selectDocflowUser(user)}>
                                                        {user.name}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>

                        <div className="form-group">
                            <TextareaAutosize type="text" name="itemDescription" id="itemDescription" value={itemDescription} onChange={this.onInputChange} autoComplete="off" />
                            <label htmlFor="itemDescription">{this.context.t('Add message')}</label>
                        </div>

                        <div className="checkbox-group first">
                            <div className={`checkbox ${emailNotification ? 'checked' : ''}`} onClick={() => this.setState({ emailNotification: !emailNotification })}></div>
                            <div className="label" onClick={() => this.setState({ emailNotification: !emailNotification })}>{this.context.t("Email notification")}</div>
                        </div>

                        <div className="checkbox-group">
                            <div className={`checkbox ${webNotification ? 'checked' : ''}`} onClick={() => this.setState({ webNotification: !webNotification })}></div>
                            <div className="label" onClick={() => this.setState({ webNotification: !webNotification })}>{this.context.t("Web app notification")}</div>
                        </div>

                        <div className="checkbox-group">
                            <div className={`checkbox ${mobileNotification ? 'checked' : ''}`} onClick={() => this.setState({ mobileNotification: !mobileNotification })}></div>
                            <div className="label" onClick={() => this.setState({ mobileNotification: !mobileNotification })}>{this.context.t("Mobile app notification")}</div>
                        </div>

                        <div className="action-buttons align-right">
                            <div className="button reset" onClick={() => this.props.close()}>
                                {this.context.t('Cancel')}
                            </div>
                            <div className="button save" onClick={() => this.saveItem()}>
                                {this.context.t('Save')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

DocflowNotificationEditWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowNotificationEditWindow)