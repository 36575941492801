import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import '../../../css/OcrPage.css'
import { CompanyActions } from '../../../actions/CompanyActions';

export class OcrPageWaproDocDescription extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showList: false,
			filter: '',
			showList: false,
			docDescriptionsList: [],
		}

		this.wrapperRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClick);
		window.addEventListener("keydown", this.escFunction, false)

		const { companyId } = this.props
		if (companyId === 1511) {
			var docDescriptionsList = require('../../../helpers/admitad-wapro-descriptions.json')
			this.setState({
				docDescriptionsList: docDescriptionsList
			})
		} else {
			var docDescriptionsList = require('../../../helpers/proxy-wapro-descriptions.json')
			this.setState({
				docDescriptionsList: docDescriptionsList
			})
		}
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick);
		window.removeEventListener("keydown", this.escFunction, false)
	}

	escFunction = e => {
		if (e.keyCode === 27) {
			this.handleClick(e)
		}
	}

	handleClick = (event) => {
		if (event.keyCode === 27 || (this.wrapperRef && !this.wrapperRef.current.contains(event.target))) {
			this.setState({
				showList: false
			})
		} else {
		}
	}

	toggleList = show => {
		this.setState({
			showList: show
		})
	}

	selectOption = code => {
		this.props.select({
			docDescription: code
		})
		this.setState({
			showList: false
		})
	}

	onInputChange = e => {
		e.preventDefault()
		const { name, value } = e.target

		this.setState({ [name]: value })
	}

	docDescriptionOnChange = e => {
		e.preventDefault()
		const { value } = e.target

		this.props.select(value)
	}

	checkIfRowMatchesFilter = row => {
		const { filter } = this.state

		var show = false

		if (filter && row) {
			if (row.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
		} else {
			show = true
		}

		return show
	}

	render() {
		const { selectedOption } = this.props
		const { filter, showList, docDescriptionsList } = this.state
		return (
			<div className={`ocr-data`} id="wapro-doc-description-container" ref={this.wrapperRef}>
				{
					showList ? (
						<div className="tags-list doc-description">
							<div className="header">
								{this.context.t('Available descriptions:')}
							</div>
							<div className="filter-row">
								<input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
							</div>
							<SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
								<ul>
									{
										docDescriptionsList.map((t, i) => {
											if (this.checkIfRowMatchesFilter(t)) {
												return (
													<li className={`tag-item ${selectedOption === t ? 'selected' : ''}`} onClick={() => this.selectOption(t)} key={`doc-description-${i}`}>{`${t}`}</li>
												)
											} else {
												return null
											}
										})
									}
								</ul>
							</SimpleBar>
						</div>
					) : (
						null
					)
				}
				<label>{this.context.t('Description')}</label>
				<TextareaAutosize
					className={`tags-container`}
					value={selectedOption}
					name='fakir-logs'
					minRows={1}
					maxRows={10}
					onFocus={() => this.toggleList(true)}
					onChange={e => this.docDescriptionOnChange(e)}
				/>
			</div>
		)
	}
}

OcrPageWaproDocDescription.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageWaproDocDescription)