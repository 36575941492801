import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { List, AutoSizer, WindowScroller } from "react-virtualized";
import { isFirefox } from "react-device-detect";

import { Digital, Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import moment from 'moment'
import ReactTooltip from 'react-tooltip'

import { FileActions } from '../../actions/FileActions'
import { TransfersActions } from '../../actions/TransfersActions'

import PdfPreview from '../PdfPreview'
import TransferPreview from '../TransferPreview'

import arrayObjectIndexOf from "../../helpers/indexOfObject"
import isIterable from "../../helpers/isIterable"


export class FileManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentDir: '',
            filterField: 'name',
            order: 'ASC',
            projectIndex: -1,
            filesToRender: [],
            waitForFilesToLoad: false
        }

        this.filesWrapper = React.createRef()
    }

    componentDidMount() {
        const { uploadedFiles, projectId } = this.props
        console.log(projectId)
        if (uploadedFiles && projectId) {
            var projectIndex = arrayObjectIndexOf(uploadedFiles, projectId, "project")
            if (projectIndex === -1) {
                //this.getFiles()
            } else {
                this.setState({
                    projectIndex: projectIndex
                })
            }
        }

        this.setCurrentDir(this.props.directory || '')
    }

    componentDidUpdate(prevProps) {
        const { uploadedFiles, projectId, directory, pdfPreviewOpen } = this.props
        if (prevProps.projectId !== projectId && uploadedFiles.find(p => p.project === projectId)) {
            this.setCurrentDir(directory)
        }

        const { waitForFilesToLoad } = this.state
        if (waitForFilesToLoad && uploadedFiles.find(p => p.project === projectId)?.loaded) {
            this.setCurrentDir(directory)
        }

        if (prevProps?.pdfPreviewOpen && !pdfPreviewOpen) {
            this.getFilesListToRender()
        }
    }

    getFiles = () => {
        this.props.getFiles(this.props.directory, this.props.projectId, 0)
    }

    switchDirectory = (path, name, fileInstance = {}) => {
        console.log(fileInstance)
        if (path !== name) {
            var updatedDir = this.state.currentDir + path + '/'
            this.props.switchDir(updatedDir, this.props.projectId)
            this.setCurrentDir(updatedDir)
        } else if (fileInstance.doc_type !== 'PAYMENT_ORDER') {
            this.props.getFileBody(parseInt(fileInstance.project_id), this.state.currentDir + name, fileInstance.id, fileInstance.ocr_status, fileInstance.acceptance_step)
        } else {
            const { transfers } = this.props
            const paymentOrdersProjectIndex = arrayObjectIndexOf(transfers, fileInstance.project_id, "project")
            console.log(paymentOrdersProjectIndex)
            if (paymentOrdersProjectIndex > -1) {
                console.log(transfers[paymentOrdersProjectIndex].transfers)
                console.log(fileInstance.id)
                var transfer = transfers[paymentOrdersProjectIndex].transfers.find(t => t.file_id === fileInstance.id)
                console.log(transfer)
                if (transfer) {
                    this.props.openTransferPreviewWindow(transfer)
                }
            }
        }
    }

    switchPaymentOrdersDirectory = (path, name, transfer) => {
        if (path === name.split('/')[0]) {
            var updatedDir = this.state.currentDir + path + '/'
            this.setCurrentDir(updatedDir)
        } else {
            if (this.props.transferPreviewData.id !== transfer.id) {
                this.props.openTransferPreviewWindow(transfer)
            } else {
                this.props.openTransferPreviewWindow()
            }
        }
    }

    goUpDirr = dir => {
        if (dir) {
            dir = dir.slice(0, -1)
            var lastSlash = dir.lastIndexOf('/')

            if (lastSlash > 0) {
                var newDir = dir.substr(0, lastSlash + 1)
                this.props.switchDir(newDir, this.props.projectId)
                this.setCurrentDir(newDir)
            }
        }
    }

    setCurrentDir = newDir => {
        this.setState({
            currentDir: newDir
        }, () => this.getFilesListToRender())
    }

    getFilesListToRender = () => {
        const { uploadedFiles, projectId } = this.props
        const { currentDir } = this.state
        const projectIndex = uploadedFiles.findIndex(files => files.project === projectId)

        var filesToRender = []
        var uniqueDirs = []
        var shouldSkipRest = false
        var foundFirstMatch = false
        var waitForFilesToLoad = false
        if (projectIndex > -1) {
            if (uploadedFiles[projectIndex].loaded) {
                const filesCount = uploadedFiles[projectIndex].files?.length || 0
                for (var i = 0; i < filesCount; i++) {
                    var currentFile = uploadedFiles[projectIndex].files[i]
                    var currentFilePath = currentFile.path
                    if (currentFilePath.includes('in progress')) break;
                    if (currentFilePath.startsWith(currentDir)) {
                        if (!foundFirstMatch) foundFirstMatch = true
                        var currentFileRelativePath = currentFilePath.replace(currentDir, '')
                        var curentFileRelativePathPaths = currentFileRelativePath.split('/')
                        if (curentFileRelativePathPaths.length === 1) {
                            filesToRender.push({
                                isFile: true,
                                ...currentFile
                            })
                        } else {
                            if (!uniqueDirs.includes(curentFileRelativePathPaths[0])) {
                                filesToRender.push({
                                    isFile: false,
                                    path: curentFileRelativePathPaths[0]
                                })
                                uniqueDirs.push(curentFileRelativePathPaths[0])
                            }
                        }
                    } else {
                        if (foundFirstMatch && shouldSkipRest) break;
                    }
                }

                if (!shouldSkipRest) {
                    const { user } = this.props
                    if (user.new_easydocs_version) {
                        const { transfers, projectId } = this.props
                        const paymentOrdersProjectIndex = arrayObjectIndexOf(transfers, projectId, "project")
                        if (paymentOrdersProjectIndex > -1 && transfers[projectIndex].transfers?.length > 0) {
                            var lastDisplayedFolder = filesToRender.pop()
                            if (lastDisplayedFolder?.path === 'deleted') {
                                filesToRender.splice(-1, 0, {
                                    isFile: false,
                                    paymentOrders: true,
                                    path: 'Payment Orders'
                                })
                            } else {
                                filesToRender.push({
                                    isFile: false,
                                    paymentOrders: true,
                                    path: 'Payment Orders'
                                })
                            }
                        }
                    }
                }
            } else {
                waitForFilesToLoad = true
            }
        }

        this.setState({
            waitForFilesToLoad: waitForFilesToLoad,
            filesToRender: filesToRender,
            projectIndex: projectIndex
        })
    }

    drawIcon = (path, name) => {
        if (path === 'paymentOrders') {
            return <div className="icon folder-icon payment-orders"></div>
        } else if (path !== name && path.includes('deleted')) {
            return <div className="icon trash-icon"></div>
        } else if (path !== name) {
            return <div className="icon folder-icon"></div>
        } else {
            var extension = name.split('.')
            extension = extension[extension.length - 1]

            if (extension === 'pdf' || extension === 'PDF') {
                return <div className="icon pdf-icon"></div>
            } else {
                return <div className="icon image-icon"></div>
            }
        }
    }

    drawPaymentOrderIcon = (path, name) => {
        if (path === name.split('/')[0]) {
            return <div className="icon folder-icon"></div>
        } else {
            return <div className="icon transfer-icon"></div>
        }
    }

    getSortClass = (project, sortField) => {
        const { uploadedFiles } = this.props

        const index = arrayObjectIndexOf(uploadedFiles, project, "project")

        if (index === -1 || uploadedFiles[index].files.length === 0) {
            return ''
        } else {
            if (uploadedFiles[index].sortField === sortField) {
                if (uploadedFiles[index].order === "asc")
                    return "icon-sort down"
                return "icon-sort up"
            }
            return "icon-sort"
        }

    }

    getProjectCode = dir => {
        if (dir) {
            return dir.replace(/\/.*$/, ' ')
        } else {
            return ''
        }
    }

    getDirWothoutProjectCode = dir => {
        console.log(dir)
        if (dir) {
            var dirParts = dir.split('/')
            var dirToShow = ''
            console.log(dirParts)
            dirParts.map(d => {
                console.log(d)
                if (d === 'in progress') dirToShow += this.context.t('In progress') + ' / '
                else if (d === 'deleted') dirToShow += this.context.t('Deleted') + ' / '
                else if (d === 'Correspondence') dirToShow += this.context.t('Correspondence ') + ' / '
                else if (d === 'Corporate') dirToShow += this.context.t('Corporate ') + ' / '
                else if (d === 'Warehouse') dirToShow += this.context.t('Warehouse ') + ' / '
                else if (d === 'Other') dirToShow += this.context.t('Other ') + ' / '
                else if (d === 'Protocol') dirToShow += this.context.t('Protocol ') + ' / '
                else if (d === 'Payment Orders') dirToShow += this.context.t('Payment Orders') + ' / '
                else dirToShow += this.context.t(d) + ' / '
            })

            if (dirToShow) dirToShow = dirToShow.slice(0, -4)
            return dirToShow
        } else {
            return ''
        }
    }

    getFileStatus = file => {
        var step1Class = '', step2Class = '', step3Class = ''
        if (file.acceptance_step > 1) {
            step1Class = 'green'
        } else {
            step1Class = 'yellow'
            step2Class = 'grey'
            step3Class = 'grey'
        }

        if (file.acceptance_step >= 2) {
            if (file.requires_additional_acceptance || file.acceptance_step === 2) {
                step2Class = 'yellow'
                step3Class = 'grey'
            } else {
                step2Class = 'green'
                step3Class = 'yellow'
            }
        }

        if (file.ocr_status === 'DONE') {
            step3Class = 'green'
        }

        return (
            <>
                <span className={`step-1 ${step1Class}`}></span>
                <span className={`step-2 ${step2Class}`}></span>
                <span className={`step-3 ${step3Class}`}></span>
            </>
        )
    }

    getFileStatusForTooltip = file => {
        if (file.acceptance_step === 1)
            return this.context.t('OCR not verified')

        if (file.acceptance_steps === 2)
            return this.context.t('OCR not accepted')

        if (file.requires_additional_acceptance)
            return this.context.t('Requires additional acceptance')

        if (file.ocr_status === 'DONE')
            return this.context.t('Paid')

        return this.context.t('Not paid')

    }

    displayAsFileOrDirecxtory = (filePath, fileName, counterpartyName, currentDir) => {
        if (filePath.replace(currentDir, '').split('/')[0] === 'in progress') {
            return this.context.t('In progress')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'deleted') {
            return this.context.t('Deleted')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Costs') {
            return this.context.t('Costs')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Expenses') {
            return this.context.t('Expenses')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Agreements') {
            return this.context.t('Agreements')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Income') {
            return this.context.t('Income')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Correspondence') {
            return this.context.t('Correspondence ')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Corporate') {
            return this.context.t('Corporate ')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Other') {
            return this.context.t('Other ')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Protocol') {
            return this.context.t('Protocol ')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Warehouse') {
            return this.context.t('Warehouse ')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'PaymentOrders') {
            return this.context.t('Payment Orders')
        } else {
            var subPath = filePath.replace(currentDir, '').split('/')[0]
            if (subPath !== fileName) return subPath
            else return counterpartyName || fileName
        }
    }

    renderAllDocTypesFolders = (projectIndex) => {
        const { uploadedFiles } = this.props
        const { currentDir } = this.state
        var displayedDirectories = []
        var foldersToRender = []

        uploadedFiles[projectIndex].files.map((file, i) => {
            if (file && file.path.startsWith(uploadedFiles[projectIndex].currentDir) && file.path.replace(currentDir, '').split('/')[0] !== 'in progress') {
                if (!displayedDirectories.includes(file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0])) {
                    displayedDirectories.push(file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0])
                    foldersToRender.push(
                        <div className="file-row" key={i} onClick={() => this.switchDirectory(file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0], file.name, file)}>
                            {this.drawIcon(file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0], file.name)}
                            <div className="name">
                                {
                                    this.displayAsFileOrDirecxtory(file.path, file.name, file.counterparty, uploadedFiles[projectIndex].currentDir)
                                }
                            </div>
                        </div>
                    )
                }
            }
        })

        if (displayedDirectories) {
            const { user } = this.props
            if (user.new_easydocs_version) {
                const { transfers, projectId } = this.props
                const paymentOrdersProjectIndex = arrayObjectIndexOf(transfers, projectId, "project")
                if (paymentOrdersProjectIndex > -1 && transfers[projectIndex].transfers?.length > 0) {
                    const paymentOrdersFolder = <div className="file-row" key={`paymentOrdersFolder`} onClick={() => this.switchDirectory('Payment Orders', '')}>
                        {this.drawIcon('paymentOrders', '')}
                        <div className="name">
                            {
                                this.context.t('Payment Orders')
                            }
                        </div>
                    </div>
                    var lastDisplayedFolder = displayedDirectories.pop()
                    if (lastDisplayedFolder === 'deleted') {
                        foldersToRender.splice(-1, 0, paymentOrdersFolder)
                    } else {
                        foldersToRender.push(paymentOrdersFolder)
                    }
                }
            }
        }

        return foldersToRender
    }

    renderFiles = (projectIndex) => {
        const { uploadedFiles } = this.props
        const { currentDir } = this.state
        var displayedDirectories = []
        var filesToRender = []

        uploadedFiles[projectIndex].files.map((file, i) => {
            if (file && file.path.startsWith(uploadedFiles[projectIndex].currentDir) && file.path.replace(currentDir, '').split('/')[0] !== 'in progress') {
                if (!displayedDirectories.includes(file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0])) {
                    displayedDirectories.push(file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0])
                    filesToRender.push(
                        <div className="file-row" key={i} onClick={() => this.switchDirectory(file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0], file.name, file)}>
                            {this.drawIcon(file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0], file.name)}
                            <div className="name">
                                {
                                    this.displayAsFileOrDirecxtory(file.path, file.name, file.counterparty, uploadedFiles[projectIndex].currentDir)
                                }
                            </div>
                            {
                                file.path.replace(uploadedFiles[projectIndex].currentDir, '').split('/')[0] === file.name ? (
                                    <>
                                        {/* <div className="modified">
                                            {moment(file.created).format('YYYY-MM-DD HH:mm')}
                                        </div> */}
                                        <div className="modified">
                                            {file.issue_date}
                                        </div>
                                        <div className="amount-to-pay">
                                            {file.amount_to_pay}
                                        </div>
                                        <div className="due-date">
                                            {file.due_date || ''}
                                        </div>
                                        <div className={`status`} data-for={`file-status-${projectIndex}-${i}`} data-tip='show'>
                                            {this.getFileStatus(file)}
                                        </div>
                                        <ReactTooltip id={`file-status-${projectIndex}-${i}`} place="left" effect="solid" className="default-tooltip status-tooltip">
                                            {this.getFileStatusForTooltip(file)}
                                        </ReactTooltip>
                                    </>
                                ) : null
                            }
                        </div>
                    )
                }
            }
        })

        return filesToRender
    }

    renderPaymentOrders = () => {
        const { transfers, projectId, directory: propsDir } = this.props
        const { currentDir } = this.state
        const paymentOrdersProjectIndex = arrayObjectIndexOf(transfers, projectId, "project")
        var directories = []
        var paymentOrdersToRender = []
        var paymentOrdersDir = currentDir.replace(`${propsDir}Payment Orders/`, '')

        if (transfers[paymentOrdersProjectIndex].transfers.length > 0) {
            transfers[paymentOrdersProjectIndex].transfers.map((transfer, i) => {
                if (transfer.name.startsWith(paymentOrdersDir)) {
                    if (!directories.includes(transfer.name.replace(paymentOrdersDir, '').split('/')[0])) {
                        directories.push(transfer.name.replace(paymentOrdersDir, '').split('/')[0])
                        paymentOrdersToRender.push(
                            <div className="file-row" key={i} onClick={() => this.switchPaymentOrdersDirectory(transfer.name.replace(paymentOrdersDir, '').split('/')[0], transfer.name, transfer)}>
                                {this.drawPaymentOrderIcon(transfer.name.replace(paymentOrdersDir, '').split('/')[0], transfer.name)}
                                <div className="name">
                                    {
                                        transfer.name.replace(paymentOrdersDir, '').split('/')[0] === transfer.name.split('/').pop() ?
                                            transfer.recipient
                                            :
                                            transfer.name.replace(paymentOrdersDir, '').split('/')[0]
                                    }
                                </div>
                                {transfer.priority && transfer.name.replace(paymentOrdersDir, '').split('/')[0] !== transfer.name.split('/')[0] ? (
                                    <div className="transfers-manager-priority">
                                        {this.context.t(' URGENT')}
                                    </div>
                                ) : (
                                    <div className="transfers-manager-priority"></div>
                                )}
                                {
                                    transfer.name.replace(paymentOrdersDir, '').split('/')[0] !== transfer.name.split('/')[0] &&
                                    <>
                                        <div className="modified">{moment(transfer.created).format('YYYY-MM-DD')}</div>
                                        <div className="amount-to-pay">{transfer.amount_to_pay ? `${parseFloat(transfer.amount_to_pay).toFixed(2)} ${transfer.currency}` : ''}</div>
                                        <div className="modified">{moment(transfer.due_date).format('YYYY-MM-DD')}</div>
                                        <div className={`status`} data-for={`file-status-${paymentOrdersProjectIndex}-${transfer.id}`} data-tip='show'>
                                            <div className={`step-1 green`}></div>
                                            <div className={`step-2 ${transfer.bank_transfers_file_id || transfer.payment_method || transfer.paid_by ? 'green' : 'yellow'}`}></div>
                                            <div className={`step-3 ${transfer.payment_method || transfer.paid_by ? 'green' : transfer.bank_transfers_file_id ? 'yellow' : 'grey'}`}></div>
                                        </div>

                                        <ReactTooltip id={`file-status-${paymentOrdersProjectIndex}-${transfer.id}`} place="left" effect="solid" className="default-tooltip status-tooltip">
                                            {
                                                transfer.payment_method || transfer.paid_by ? (
                                                    this.context.t('Paid')
                                                ) : (
                                                    transfer.bank_transfers_file_id ? (
                                                        this.context.t('Included in bank transfers file')
                                                    ) : (
                                                        this.context.t('Not included in bank transfers file')
                                                    )
                                                )
                                            }
                                        </ReactTooltip>

                                    </>
                                }
                            </div>
                        )
                    }
                }
            })
        }

        return paymentOrdersToRender
    }



    setRef = windowScroller => {
        this._windowScroller = windowScroller;
    }

    setListRef = listRef => {
        this.listRef = listRef
    }

    rowGetter = ({ index }) => {
        const { filesToRender } = this.state

        return filesToRender[index]
    }


    getRowHeight = ({ index }) => {
        return 20
    }

    getFileIcon = (isFile, filePath) => {
        if (filePath === 'deleted') return "trash-icon"
        if (filePath === 'PaymentOrders') return "payment-orders-folder-icon"
        if (!isFile) return 'folder-icon'

        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(filePath)[1]
        if (extension === 'pdf' || extension === 'PDF') return "pdf-icon"
        return "image-icon"
    }

    rowRenderer = ({ style, key, index, parent, width }) => {
        const { filesToRender, currentDir, currentProjectId } = this.state

        var file = filesToRender[index]

        return (
            <div
                key={key}
                style={style}
                role="row"
                className="ReactVirtualized__List-row file-row"
                onClick={() => this.switchDirectory(file.path.replace(currentDir, '').split('/')[0], file.name, file)}
            >
                <div className={`ReactVirtualized__List-cell icon ${this.getFileIcon(file.isFile, file.path)}`}></div>
                <div className={`ReactVirtualized__List-cell name ${file.isFile ? 'file' : 'folder'}`}>
                    {this.displayAsFileOrDirecxtory(file.path, file.name || '', file.counterparty || '', currentDir)}
                </div>
                {
                    file.isFile ? (
                        <div className="ReactVirtualized__List-cell modified">
                            <div className="text">{file.issue_date}</div>
                        </div>
                    ) : null
                }
                {
                    file.isFile ? (
                        <div className="ReactVirtualized__List-cell amount-to-pay">
                            <div className="text">{file.amount_to_pay}</div>
                        </div>
                    ) : null
                }
                {
                    file.isFile ? (
                        <div className="ReactVirtualized__List-cell due-date">
                            <div className="text">{file.due_date || ''}</div>
                        </div>
                    ) : null
                }
                {
                    file.isFile ? (
                        <>
                            <div className={`ReactVirtualized__List-cell status`} data-for={`file-status--${file.id}`} data-tip='show'>
                                {this.getFileStatus(file)}
                            </div>

                            <ReactTooltip id={`file-status--${file.id}`} place="left" effect="solid" className="default-tooltip status-tooltip">
                                {this.getFileStatusForTooltip(file)}
                            </ReactTooltip>
                        </>
                    ) : null
                }
            </div>
        )
    }

    render() {
        const {
            user,
            directory: propsDir,
            uploadedFiles,
            projectId,
            simpleBarHeight, noContentImageHeight,
            pdfPreviewOpen, transferPreviewOpen,
            unpaidDocsPreviewIsOpen, fullTextSearchPreviewIsOpen, actionsPreviewIsOpen, previewClickFromChatNotification
        } = this.props
        const { currentDir, filesToRender, projectIndex } = this.state

        // const projectIndex = uploadedFiles.findIndex(files => files.project === projectId)

        if (projectIndex > -1 && uploadedFiles[projectIndex].sendRequest && uploadedFiles[projectIndex].loaded) {
            return (
                <div className={`project-files ${uploadedFiles[projectIndex].extended ? 'extended' : ''}`}>
                    {
                        pdfPreviewOpen && !unpaidDocsPreviewIsOpen && !fullTextSearchPreviewIsOpen && !actionsPreviewIsOpen && !previewClickFromChatNotification ? (
                            <PdfPreview location={this.props.location} />
                        ) : (
                            null
                        )
                    }

                    {
                        user.new_easydocs_version && transferPreviewOpen ? (
                            <TransferPreview location={this.props.location} />
                        ) : (
                            null
                        )
                    }

                    <div className="header">
                        {
                            !uploadedFiles[projectIndex].extended ? (
                                <div className="text"><h2>{this.context.t('Binder docs')}</h2></div>
                            ) : (
                                <div className="text extended">
                                    <div className="folder-icon"></div>
                                    <div className="go-back" onClick={() => this.goUpDirr(currentDir)}></div>
                                    <h2>{this.getProjectCode(currentDir)}{this.context.t('Docs /')} <span>{this.getDirWothoutProjectCode(currentDir)}</span></h2>
                                </div>
                            )
                        }
                        <div className={`expent-button ${uploadedFiles[projectIndex].extended ? 'extended' : ''}`} onClick={() => this.props.toggleProjectFiles(uploadedFiles[projectIndex].project)}></div>
                    </div>
                    {
                        uploadedFiles[projectIndex].extended ? (
                            <>
                                <div className="file-row header-row">
                                    <div className="name">
                                        {this.context.t('Name')}
                                    </div>
                                    <div className="modified">
                                        {this.context.t('Issue date')}
                                    </div>
                                    <div className="amount-to-pay">
                                        {this.context.t('Amount to pay')}
                                    </div>
                                    <div className="due-date">
                                        {this.context.t('Due date')}
                                    </div>
                                    <div className="status">
                                        {this.context.t('Status')}
                                    </div>
                                </div>
                                <div className={`dashboard-file-manager ${isFirefox ? 'is-firefox' : ''}`} ref={this.filesWrapper} style={{ height: simpleBarHeight, width: 'calc(100% + 8px)', paddingRight: '8px', marginRight: '-8px', marginTop: '4px' }}>
                                    {/* {
                                        uploadedFiles[projectIndex].files.length > 0 ? (
                                            currentDir !== propsDir ? (
                                                currentDir.startsWith(`${propsDir}Payment Orders/`) ? (
                                                    this.renderPaymentOrders()
                                                ) : (
                                                    this.renderFiles(projectIndex)
                                                )
                                            ) : (
                                                this.renderAllDocTypesFolders(projectIndex)
                                            )
                                        ) : (
                                            <div className="no-files-indicator" style={{ height: noContentImageHeight }}>
                                                <div className="image no-uploaded-files"></div>
                                                <div className="text">{this.context.t("It looks like you don't have any files")}</div>
                                            </div>
                                        )
                                    } */}
                                    <AutoSizer disableHeight>
                                        {({ width }) => (
                                            <List
                                                ref={this.setListRef}
                                                width={width}
                                                height={this.filesWrapper?.current?.clientHeight || 100}
                                                rowHeight={this.getRowHeight}

                                                rowCount={filesToRender.length}
                                                rowGetter={this.rowGetter}
                                                rowRenderer={({ style, key, index, parent }) => this.rowRenderer({ style, key, index, parent, width })}
                                                overscanRowCount={2}
                                            />
                                        )}
                                    </AutoSizer>
                                </div>
                            </>
                        ) : (
                            null
                        )
                    }
                </div>
            )
        } else if (projectIndex > -1) {
            return (
                <div className={`project-files ${uploadedFiles[projectIndex].extended ? 'extended' : ''}`}>
                    <div className="header">
                        {
                            !uploadedFiles[projectIndex].extended ? (
                                <div className="text"><h2>{this.context.t('Binder docs')}</h2></div>
                            ) : (
                                <div className="text"><h2>{this.context.t('Binder docs')}</h2></div>
                            )
                        }
                        <div className={`expent-button ${uploadedFiles[projectIndex].extended ? 'extended' : ''}`} onClick={() => this.props.toggleProjectFiles(uploadedFiles[projectIndex].project)}></div>
                    </div>
                    {
                        uploadedFiles[projectIndex].extended ? (
                            <SimpleBar style={{ height: noContentImageHeight, width: '100%' }}>
                                <div className="activity-indicator">
                                    <Bounce size={30} speed={0.8} color={"#BCBBBB"} />
                                </div>
                            </SimpleBar>
                        ) : (
                            null
                        )
                    }
                </div>
            )
        } else {
            return (
                <div className={`project-files`}>
                    <div className="header">
                        <div className="text"><h2>{this.context.t('Binder docs')}</h2></div>
                    </div>
                </div>
            )
        }

    }
}


FileManager.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    uploadedFiles: isIterable(state.File.uploadedFiles) ? [...state.File.uploadedFiles] : [],
    pdfPreviewData: state.File.pdfPreviewData,
    pdfPreviewOpen: state.File.pdfPreviewOpen,
    unpaidDocsPreviewIsOpen: state.File.unpaidDocsPreviewIsOpen,
    fullTextSearchPreviewIsOpen: state.File.fullTextSearchPreviewIsOpen,
    actionsPreviewIsOpen: state.File.actionsPreviewIsOpen,
    previewClickFromChatNotification: state.File.previewClickFromChatNotification,

    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
    transferPreviewData: state.Transfers.transferPreviewData,
    transferPreviewOpen: state.Transfers.transferPreviewOpen,
})

const mapDispatchToProps = {
    getFiles: FileActions.getFiles,
    switchDir: FileActions.switchDir,
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview,
    switchSortingFilter: FileActions.switchSortingFilterUploadedFiles,
    toggleProjectFiles: FileActions.toggleProjectFiles,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileManager)