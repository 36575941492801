import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isFirefox } from "react-device-detect";
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd';
import DropTarget from '../DocflowComponents/DropTarget'
import DragLayer from '../DocflowComponents/DragLayer'
import DragItem from '../DocflowComponents/DragItem'
import DocflowStageEditWindow from '../DocflowComponents/DocflowStageEditWindow'
import DocflowNotificationEditWindow from '../DocflowComponents/DocflowNotificationEditWindow'
import DocflowConditionEditWindow from '../DocflowComponents/DocflowConditionEditWindow'
import DocflowsTable from '../DocflowComponents/DocflowsTable'
import DocflowCopyWindow from '../DocflowComponents/DocflowCopyWindow'
import DocflowArchiveWindow from '../DocflowComponents/DocflowArchiveWindow'

import availableQuickActions from '../../helpers/availableQuickActions'
import { AVAILABLE_STAGES, AVAILABLE_EVENTS, AVAILABLE_DOC_TYPES, AVAILABLE_DEDLINES } from '../../helpers/docflowHelpers'


import QuickActionPopupHeader from './QuickActionPopupHeader'
import FilesPreviewInReports from '../ReportsPageComponents/FilesPreviewInReports'

import { FileActions } from '../../actions/FileActions'
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { DocflowActions } from '../../actions/DocflowActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { AlertActions } from '../../actions/AlertActions'
import { ReportsActions } from '../../actions/ReportsActions'
import { Spinner } from 'react-activity'
import { isType } from 'pdf-lib';
import { isNumber } from 'lodash';

import '../../css/QuickActions.css'
import '../../css/Report.css'

export class DocflowAssistant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEditingExistingDocflow: false,
            docflowSchema: [],
            docflowName: '',
            docflowBinderId: -1,
            docflowBinderName: '',
            availableDocFlowBinders: [],
            showDocflowBindersList: false,
            docflowDocTypes: [],
            canUserVerification: true,
            canUsePayment: true,
            canUseMpk: true,
            canUseDevEx: true,
            showDocflowDocTypes: false,
            docflowDescription: '',
            availableDocflowUsers: [],
            showNoAdminMessage: false
        }

        this.popupWindowWrapperRef = React.createRef()
        this.docflowBinderWrapperRef = React.createRef()
        this.docflowDocTypesWrapperRef = React.createRef()
    }

    componentDidMount() {
        const { userCompanies, userProjects, currentProject } = this.props
        var companiesWithAdminRole = []
        userCompanies.forEach(c => {
            if (c.user_role === 'ADMIN') companiesWithAdminRole.push(c.company_id)
        })

        var availableProjects = []
        userProjects.forEach(p => {
            if (companiesWithAdminRole.includes(p.company_id)) availableProjects.push({
                id: p.id,
                code: p.code
            })
        })

        if (availableProjects.find(p => p.id === currentProject.id)) {
            this.props.getProjectDocflows(currentProject.id)
        } else {
            this.setState({
                showNoAdminMessage: true
            })
        }

        this.setState({
            availableDocFlowBinders: availableProjects
        })

        window.addEventListener("keydown", this.keyDownHandler, false)
        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
        const { currentProject } = this.props

        if (currentProject?.id !== prevProps?.currentProject?.id) {
            this.props.getProjectDocflows(currentProject.id)
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyDownHandler, false)
        document.removeEventListener("mousedown", this.handleClick);
    }

    keyDownHandler = e => {
        if (e.keyCode === 27) {

        }
    }

    handleClick = event => {
        // if (this.popupWindowWrapperRef && !this.popupWindowWrapperRef.current.contains(event.target)) {
        //     this.props.toggleQuickAction(availableQuickActions.docflowAssistant, false)
        // } else
        if (this.state.showDocflowBindersList && this.docflowBinderWrapperRef && !this.docflowBinderWrapperRef.current.contains(event.target)) {
            this.setState({
                showDocflowBindersList: false
            })
        } else if (this.state.showDocflowDocTypes && this.docflowDocTypesWrapperRef && !this.docflowDocTypesWrapperRef.current.contains(event.target)) {
            this.setState({
                showDocflowDocTypes: false
            })
        }
    }

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target

        this.setState({ [name]: value })
    }

    addAndSort = (item, hoverIndex) => {
        console.log('addAndSort')
        const { docflowSchema } = this.state
        var dragItem = { ...item }
        dragItem.isPseudo = true

        var tempDocFlow = docflowSchema.filter(e => !e.isPseudo)
        tempDocFlow.splice(hoverIndex, 0, dragItem)

        this.setState({
            docflowSchema: tempDocFlow
        });
    }

    handleDrop = (item, dropTargetName) => {
        console.log('handleDrop')
        var itemToAdd = { ...item }
        const { docflowSchema } = this.state
        console.log(docflowSchema)
        if (dropTargetName === 'docflow') {
            var tempDocFlow = [...docflowSchema]
            //add
            if (tempDocFlow.find(e => e.id === itemToAdd.id && e.isPseudo)) {
                tempDocFlow.forEach(e => {
                    if (e.isPseudo) delete e.isPseudo
                    if (typeof e.id !== "number" && !e.id.startsWith('new_')) e.id = `new_${(Math.random() + 1).toString(36).substring(7)}`
                })
            } else {
                if (typeof itemToAdd.id !== "number" && !itemToAdd.id.startsWith('new_')) itemToAdd.id = `new_${(Math.random() + 1).toString(36).substring(7)}`
                tempDocFlow.push(itemToAdd)
            }

            tempDocFlow = tempDocFlow.filter(e => !e.isPseudo)

            this.setState({
                docflowSchema: tempDocFlow,
            })
        } else {
            //sort
            var tempDocFlow = [...docflowSchema]
            tempDocFlow = tempDocFlow.filter(e => !e.isPseudo)
            this.setState({
                docflowSchema: tempDocFlow
            })
        }
    }

    removeAllPseudoElements = () => {
        console.log('removeAllPseudoElements')
        const { docflowSchema } = this.state
        var tempDocFlow = [...docflowSchema]
        tempDocFlow = tempDocFlow.filter(e => !e.isPseudo)
        this.setState({
            docflowSchema: tempDocFlow
        })
    }

    startDraggingDocflowElement = itemId => {
        console.log('startDraggingDocflowElement')
        console.log(itemId)
        const { docflowSchema } = this.state
        var tempDocFlow = [...docflowSchema]
        tempDocFlow.forEach(e => {
            if (e.id === itemId) e.isPseudo = true
        })
        this.setState({
            docflowSchema: tempDocFlow
        })
    }

    sortDocflow = (dragIndex, hoverIndex) => {
        console.log('sortDocflow')
        const { docflowSchema } = this.state
        const dragItem = docflowSchema[dragIndex]

        var tempDocFlow = [...docflowSchema]
        tempDocFlow.splice(dragIndex, 1)
        dragItem.isPseudo = true
        tempDocFlow.splice(hoverIndex, 0, dragItem)

        this.setState({
            docflowSchema: tempDocFlow
        });
    }

    editDocflowItemClick = (item, index) => {
        const { docflowBinderId, docflowDocTypes } = this.state
        if (docflowBinderId === -1) {
            this.props.alertWarn(this.context.t('First, select the binder where you want to add this flow so that you can assign the right people to it'))
        }
        // Will be unlocked with OCR page rework
        // else if (item.itemType === 'verification' && docflowDocTypes.length === 0) {
        //     this.props.alertWarn(this.context.t('First, select the document type to determine which fields will be required at the verification stage'))
        // } 
        else {
            this.setState({
                showEditDocflowItemWindow: true,
                editedDocflowItemType: item.itemCategory === 'stage' ? 'STAGE' : item.itemType.toUpperCase(),
                editedDocflowItem: {
                    ...item,
                    index: index,
                    docflowItemType: item.itemCategory === 'stage' ? 'STAGE' : item.itemType.toUpperCase(),
                }
            })
        }
    }

    removeDocflowItemClick = itemtId => {
        const { docflowSchema } = this.state

        var tempDocFlow = [...docflowSchema]
        tempDocFlow = tempDocFlow.filter(e => e.id !== itemtId)

        this.setState({
            docflowSchema: tempDocFlow
        });
    }

    cancelDocflowItemEdit = () => {
        this.setState({
            showEditDocflowItemWindow: false,
            editedDocflowItemType: '',
            editedDocflowItem: {}
        })
    }

    saveDocflowItemEdit = newData => {
        console.log(newData)
        const { editedDocflowItem, docflowSchema } = this.state
        var tempDocFlow = [...docflowSchema]

        tempDocFlow.forEach((item, index) => {
            console.log(item)
            if (item.id === editedDocflowItem.id) {
                console.log('found match')
                tempDocFlow[index] = newData
            }
            console.log(item)
        })

        console.log(tempDocFlow)

        this.setState({
            docflowSchema: tempDocFlow,
            showEditDocflowItemWindow: false,
            editedDocflowItemType: '',
            editedDocflowItem: {}
        });
    }

    toggleDocflowBindersList = () => {
        const { showDocflowBindersList } = this.state
        this.setState({
            showDocflowBindersList: !showDocflowBindersList
        })
    }

    selectDocflowBinder = binder => {
        const { docflowBinderId } = this.state
        if (docflowBinderId !== binder.id) {
            this.props.getProjectMembersForDocflow(binder.id, members => {
                if (members) {
                    this.setState({
                        availableDocflowUsers: members
                    })
                }
            })
        }

        this.setState({
            docflowBinderName: binder.code,
            docflowBinderId: binder.id,
            showDocflowBindersList: false
        })
    }

    toggleDocflowDocTypesList = (event) => {
        const clickedElement = event?.target || {};
        const clickedElementClass = clickedElement?.className || '';

        if (clickedElementClass.includes('remove')) {
            console.log('Remove button was clicked');
        } else {
            const { showDocflowDocTypes } = this.state
            this.setState({
                showDocflowDocTypes: !showDocflowDocTypes
            })
        }
    }

    removeDocflowDocType = docType => {
        const { docflowDocTypes } = this.state
        var tempDocflowDocTypes = [...docflowDocTypes]

        tempDocflowDocTypes = tempDocflowDocTypes.filter(t => t.docType !== docType)

        this.setState({
            docflowDocTypes: tempDocflowDocTypes,
            showDocflowDocTypes: false,
            canUserVerification: !tempDocflowDocTypes.find(t => ['PAYMENT_ORDER', 'SALE_INVOICE'].includes(t.docType)),
            canUsePayment: !tempDocflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE'].includes(t.docType)),
            canUseMpk: !tempDocflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE', 'EXPENSES'].includes(t.docType)),
            canUseDevEx: !tempDocflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE', 'EXPENSES'].includes(t.docType))
        })
    }

    checkIfCanSelectDocType = (docType, isOnClick) => {
        const { docflowDocTypes, docflowSchema } = this.state
        console.log(docType)
        console.log(docflowDocTypes)
        var canSelect = true
        if (docflowDocTypes.length) {
            if (docflowDocTypes.find(t => t.docType === 'WAREHOUSE') && docType !== 'COSTS') canSelect = false
            if (docType === 'COSTS' && docflowDocTypes.find(t => t.docType !== 'WAREHOUSE')) canSelect = false
            if (docflowDocTypes.find(t => t.docType === 'COSTS') && docType !== 'WAREHOUSE') canSelect = false
            if (docType === 'WAREHOUSE' && docflowDocTypes.find(t => t.docType !== 'COSTS')) canSelect = false
            if (docflowDocTypes.find(t => ['EXPENSES', 'PAYMENT_ORDER', 'SALE_INVOICE'].includes(t.docType))) canSelect = false
            if (['EXPENSES', 'PAYMENT_ORDER', 'SALE_INVOICE'].includes(docType)) canSelect = false
        }

        if (!canSelect) {
            if (isOnClick) this.props.alertWarn(this.context.t('These document types cannot be used simultaneously due to differences in their life cycle in the system'), 10000)
        } else {
            if (!['COSTS', 'WAREHOUSE', 'PAYMENT_ORDER'].includes(docType) && docflowSchema.find(e => e.itemType === 'payment')) {
                canSelect = false
                if (isOnClick) this.props.alertWarn(this.context.t('This document type cannot be selected because a \"{n}\" block was used in the path', { n: this.context.t("Payment") }), 10000)
            }

            if (['PAYMENT_ORDER', 'SALE_INVOICE'].includes(docType) && docflowSchema.find(e => e.itemType === 'verification')) {
                canSelect = false
                if (isOnClick) this.props.alertWarn(this.context.t('This document type cannot be selected because a \"{n}\" block was used in the path', { n: this.context.t("Verification") }), 10000)
            }

            if (!['COSTS', 'WAREHOUSE', 'PAYMENT_ORDER', 'EXPENSES'].includes(docType) && docflowSchema.find(e => e.itemType === 'mpk')) {
                canSelect = false
                if (isOnClick) this.props.alertWarn(this.context.t('This document type cannot be selected because a \"{n}\" block was used in the path', { n: 'MPK' }), 10000)
            }

            if (!['COSTS', 'WAREHOUSE', 'PAYMENT_ORDER', 'EXPENSES'].includes(docType) && docflowSchema.find(e => e.itemType === 'devEx')) {
                canSelect = false
                if (isOnClick) this.props.alertWarn(this.context.t('This document type cannot be selected because a \"{n}\" block was used in the path', { n: 'DevEx' }), 10000)
            }
        }

        return canSelect
    }

    selectDocflowType = type => {
        const { docflowDocTypes } = this.state
        var tempDocflowDocTypes = [...docflowDocTypes]

        if (tempDocflowDocTypes.find(t => t.docType === type.docType)) {
            tempDocflowDocTypes = tempDocflowDocTypes.filter(t => t.docType !== type.docType)
        } else {
            if (this.checkIfCanSelectDocType(type.docType, true)) {
                tempDocflowDocTypes.push(type)
            } else {
                this.setState({
                    showDocflowDocTypes: false
                })
            }
        }

        this.setState({
            showDocflowDocTypes: false,
            docflowDocTypes: tempDocflowDocTypes,
            canUserVerification: !tempDocflowDocTypes.find(t => ['PAYMENT_ORDER', 'SALE_INVOICE'].includes(t.docType)),
            canUsePayment: !tempDocflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE'].includes(t.docType)),
            canUseMpk: !tempDocflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE', 'EXPENSES'].includes(t.docType)),
            canUseDevEx: !tempDocflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE', 'EXPENSES'].includes(t.docType))
        })
    }

    resetDocflowChanges = () => {
        const { isEditingExistingDocflow, editedDocflow } = this.state
        this.setState({
            docflowName: '',
            docflowBinderId: -1,
            docflowBinderName: '',
            showDocflowBindersList: false,
            docflowDocTypes: [],
            showDocflowDocTypes: false,
            docflowDescription: '',
            docflowSchema: [],
            canUserVerification: true,
            canUsePayment: true,
            canUseMpk: true,
            canUseDevEx: true,
            hasUnsavedChanges: false,
            isEditingExistingDocflow: false,
            editedDocflow: null
        })
    }

    saveDocflow = () => {
        const {
            docflowSchema,
            docflowName, docflowBinderId, docflowDocTypes, docflowDescription,

            isEditingExistingDocflow, editedDocflow
        } = this.state

        var isValid = true

        if (!docflowName) {
            isValid = false
            this.props.alertWarn('Docflow name is required')
        }

        if (docflowBinderId === -1) {
            isValid = false
            this.props.alertWarn('Binder is required')
        }

        if (docflowDocTypes.length === 0) {
            isValid = false
            this.props.alertWarn('Doc type is required')
        }

        if (docflowSchema.length === 0) {
            isValid = false
            this.props.alertWarn('Docflow must have at least one element')
        }

        if (isValid) {
            if (isEditingExistingDocflow) {
                var deletedSteps = []
                editedDocflow.docflowSteps.forEach(step => {
                    if (!docflowSchema.find(e => e.id === step.id)) deletedSteps.push(step.id)
                })
                this.props.saveDocflowChanges(editedDocflow.id, docflowBinderId, docflowName, docflowDescription, docflowDocTypes, docflowSchema, deletedSteps, success => {
                    if (success) {
                        this.props.getProjectDocflows(docflowBinderId)
                        this.resetDocflowChanges()
                    }
                })
            }
            else {
                this.props.createNewDocflow(docflowBinderId, docflowName, docflowDescription, docflowDocTypes, docflowSchema, success => {
                    if (success) {
                        this.props.getProjectDocflows(docflowBinderId)
                        this.resetDocflowChanges()
                    }
                })
            }
        }
    }

    checkIfStageCanBeUsed = stageType => {
        const {
            canUserVerification, canUsePayment, canUseMpk, canUseDevEx
        } = this.state
        var canBeUsed = true

        if (stageType === 'verification' && !canUserVerification) canBeUsed = false
        if (stageType === 'payment' && !canUsePayment) canBeUsed = false
        if (stageType === 'mpk' && !canUseMpk) canBeUsed = false
        if (stageType === 'devEx' && !canUseDevEx) canBeUsed = false

        return canBeUsed
    }

    mapDeadlineIdToLabel = (deadLineId, stepType) => {
        var deadlineObject = AVAILABLE_DEDLINES.find(d => d.id === deadLineId)
        if (deadlineObject) {
            if (stepType === 'VERIFICATION') return deadlineObject.uploadLabel
            else return deadlineObject.label
        } else return ''
    }

    mapSavedDocflowToWebSchema = (schemaFromBase, createCopy) => {
        var schemaToReturn = []
        schemaFromBase.forEach(step => {
            schemaToReturn.push({
                id: createCopy ? `new_${(Math.random() + 1).toString(36).substring(7)}` : step.id,
                itemType: step.step_type.toLowerCase(),
                itemCategory: ['CONDITION', 'NOTIFICATION'].includes(step.step_type) ? 'event' : 'stage',
                title: step.name,
                itemDescription: step.description,
                docflowItemType: ['CONDITION', 'NOTIFICATION'].includes(step.step_type) ? step.step_type : 'STAGE',
                itemDeadlineId: step.deadline,
                itemDeadlineLabel: this.mapDeadlineIdToLabel(step.deadline, step.step_type),
                selectedUsers: step.additional_settings?.selectedUsers || [],
                requiredFields: step.additional_settings?.requiredFields || [],
                notifyAboutOvberdue: step.additional_settings?.notifyAboutOvberdue || false,
                canBeRevoked: step.additional_settings?.canBeRevoked || false,
                canBeSkipped: step.additional_settings?.canBeSkipped || false,
                conditionBlocks: step.additional_settings?.conditionBlocks || null,
            })
        })
        return schemaToReturn
    }

    editDocflow = (docflow, force) => {
        const { currentProject } = this.props
        const { hasUnsavedChanges } = this.state

        if (hasUnsavedChanges && !force) {
        } else {
            var docflowSchemaToEdit = this.mapSavedDocflowToWebSchema(docflow.docflowSteps, false)
            this.setState({
                docflowSchema: docflowSchemaToEdit,
                docflowName: docflow.name,
                docflowBinderId: currentProject.id,
                docflowBinderName: currentProject.code,
                docflowDocTypes: docflow.doc_types.map(type => AVAILABLE_DOC_TYPES.find(t => t.docType === type)),
                docflowDescription: docflow.description,

                isEditingExistingDocflow: true,
                editedDocflow: { ...docflow },
                hasUnsavedChanges: false
            }, () => {
                const { docflowDocTypes } = this.state
                this.setState({
                    canUserVerification: !docflowDocTypes.find(t => ['PAYMENT_ORDER', 'SALE_INVOICE'].includes(t.docType)),
                    canUsePayment: !docflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE'].includes(t.docType)),
                    canUseMpk: !docflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE', 'EXPENSES'].includes(t.docType)),
                    canUseDevEx: !docflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE', 'EXPENSES'].includes(t.docType))
                })
            })

            this.props.getProjectMembersForDocflow(currentProject.id, members => {
                if (members) {
                    this.setState({
                        availableDocflowUsers: members
                    })
                }
            })
        }
    }

    copyDocflowClick = (docflow) => {
        const { availableDocFlowBinders } = this.state
        if (availableDocFlowBinders.length > 1) {
            this.setState({
                showCopyDocflowWindow: true,
                docflowToCopy: docflow
            })
        } else {
            const { currentProject } = this.props
            this.copyDocflow(docflow, false, currentProject.id, currentProject.code)
        }
    }

    closeDocflowCopyWindow = () => {
        this.setState({
            showCopyDocflowWindow: false,
            docflowToCopy: null
        })
    }

    copyDocflow = (docflow, changedProject, projectId, projectCode) => {
        var docflowSchemaToEdit = this.mapSavedDocflowToWebSchema(docflow.docflowSteps, true)
        this.setState({
            docflowSchema: docflowSchemaToEdit,
            docflowName: docflow.name,
            docflowBinderId: projectId,
            docflowBinderName: projectCode,
            docflowDocTypes: docflow.doc_types.map(type => AVAILABLE_DOC_TYPES.find(t => t.docType === type)),
            docflowDescription: docflow.description,
            hasUnsavedChanges: false
        }, () => {
            this.closeDocflowCopyWindow()
            const { docflowDocTypes } = this.state
            this.setState({
                canUserVerification: !docflowDocTypes.find(t => ['PAYMENT_ORDER', 'SALE_INVOICE'].includes(t.docType)),
                canUsePayment: !docflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE'].includes(t.docType)),
                canUseMpk: !docflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE', 'EXPENSES'].includes(t.docType)),
                canUseDevEx: !docflowDocTypes.find(t => !['PAYMENT_ORDER', 'COSTS', 'WAREHOUSE', 'EXPENSES'].includes(t.docType))
            })
        })

        if (changedProject) {
            this.props.getProjectMembersForDocflow(projectId, members => {
                if (members) {
                    this.setState({
                        availableDocflowUsers: members
                    })
                }
            })
        }
    }


    archiveDocflowClick = docflow => {
        this.setState({
            showArchiveDocflowWindow: true,
            docflowToArchive: docflow
        })
    }

    archiveDocflow = () => {
        const { currentProject } = this.props
        const { docflowToArchive } = this.state
        this.props.archiveDocflow(docflowToArchive.id, docflowToArchive.project_id, success => {
            if (success) {
                this.props.getProjectDocflows(currentProject.id)
            }
            this.setState({
                showArchiveDocflowWindow: false,
                docflowToArchive: null
            })
        })
    }

    render() {
        const {
            docflowSchema,
            docflowName, docflowBinderId, docflowBinderName, availableDocFlowBinders, docflowDocTypes, docflowDescription,
            showDocflowBindersList, showDocflowDocTypes,
            availableDocflowUsers,
            showEditDocflowItemWindow, editedDocflowItemType, editedDocflowItem,
            showNoAdminMessage,

            isEditingExistingDocflow, editedDocflow, hasUnsavedChanges,
            showCopyDocflowWindow, docflowToCopy,
            showArchiveDocflowWindow, docflowToArchive
        } = this.state
        const {
            user
        } = this.props


        console.log(docflowSchema)
        return (
            <div className={`quic-actions-popup docflow ${showEditDocflowItemWindow || showCopyDocflowWindow ? 'waiting-for-actions' : ''} ${isFirefox ? 'is-firefox' : ''}`}>
                {
                    showEditDocflowItemWindow && editedDocflowItemType === 'STAGE' ? (
                        <DocflowStageEditWindow
                            editedDocflowItem={editedDocflowItem}
                            availableDocflowUsers={availableDocflowUsers}
                            docflowDocTypes={docflowDocTypes}
                            save={this.saveDocflowItemEdit}
                            close={this.cancelDocflowItemEdit}
                        />
                    ) : null
                }

                {
                    showEditDocflowItemWindow && editedDocflowItemType === 'NOTIFICATION' ? (
                        <DocflowNotificationEditWindow
                            editedDocflowItem={editedDocflowItem}
                            availableDocflowUsers={availableDocflowUsers}
                            save={this.saveDocflowItemEdit}
                            close={this.cancelDocflowItemEdit}
                        />
                    ) : null
                }

                {
                    showEditDocflowItemWindow && editedDocflowItemType === 'CONDITION' ? (
                        <DocflowConditionEditWindow
                            editedDocflowItem={editedDocflowItem}
                            availableDocflowUsers={availableDocflowUsers}
                            save={this.saveDocflowItemEdit}
                            close={this.cancelDocflowItemEdit}
                        />
                    ) : null
                }

                {
                    showCopyDocflowWindow ? (
                        <DocflowCopyWindow
                            docflowToCopy={docflowToCopy}
                            availableDocFlowBinders={availableDocFlowBinders}
                            copy={this.copyDocflow}
                            close={() => this.setState({ showCopyDocflowWindow: false, docflowToArchive: null })}
                        />
                    ) : null
                }

                {
                    showArchiveDocflowWindow ? (
                        <DocflowArchiveWindow
                            docflowToArchive={docflowToArchive}
                            archive={this.archiveDocflow}
                            close={() => this.setState({ showArchiveDocflowWindow: false, docflowToArchive: null })}
                        />
                    ) : null
                }

                <DndProvider backend={HTML5Backend}>
                    <DragLayer translate={this.context.t} />
                    <div className={`content docflow-assistant`} ref={this.popupWindowWrapperRef}>
                        <QuickActionPopupHeader
                            title={"Docflow assistant"}
                            type={"docflowAssistant"}
                            close={() => this.props.toggleQuickAction(availableQuickActions.docflowAssistant, false)}
                            getDocs={() => { }}
                        />

                        <DocflowsTable
                            showNoAdminMessage={showNoAdminMessage}
                            availableDocFlowBinders={availableDocFlowBinders}
                            editDocflow={this.editDocflow}
                            copyDocflowClick={this.copyDocflowClick}
                            archiveDocflowClick={this.archiveDocflowClick}
                        />

                        <div className="docflow-editor">
                            <h2>{this.context.t('Document flow creator')}</h2>
                            <h3>
                                {
                                    isEditingExistingDocflow ? (
                                        this.context.t('Edited docflow: {n}', { n: editedDocflow?.name })
                                    ) : (
                                        this.context.t('Creating a new docflow')
                                    )
                                }
                            </h3>

                            <div className="editor-container">
                                <div className="settings-section docflow-settings">
                                    <div className="form-group">
                                        <input type="text" name="docflowName" id="docflowName" value={docflowName} onChange={this.onInputChange} autoComplete="off" />
                                        <label htmlFor="docflowName">{this.context.t('Document flow name')}</label>
                                    </div>
                                    <div className={`form-group ${showDocflowBindersList ? 'in-front' : ''}`} ref={this.docflowBinderWrapperRef}>
                                        <input type="text" name="docflowBinderName" id="docflowBinderName" value={docflowBinderName} onClick={this.toggleDocflowBindersList} autoComplete="off" />
                                        <label htmlFor="docflowBinderName" onClick={this.toggleDocflowBindersList}>{this.context.t('Binder')}</label>
                                        <span className={`expend-icon ${showDocflowBindersList ? 'expended' : ''}`} onClick={this.toggleDocflowBindersList}></span>

                                        {
                                            showDocflowBindersList ? (
                                                <ul>
                                                    {
                                                        availableDocFlowBinders.map(b => {
                                                            return (
                                                                <li className={`${docflowBinderId === b.id ? 'selected' : ''}`} onClick={() => this.selectDocflowBinder(b)}>
                                                                    {b.code}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            ) : null
                                        }
                                    </div>
                                    <div className={`form-group ${showDocflowDocTypes ? 'in-front' : ''}`} ref={this.docflowDocTypesWrapperRef}>
                                        <div className="selected-elements" onClick={this.toggleDocflowDocTypesList}>
                                            {
                                                docflowDocTypes.map(type => {
                                                    return <div className="element">
                                                        {this.context.t(type.label)}
                                                        <span className="remove" onClick={() => this.removeDocflowDocType(type.docType)}></span>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <label htmlFor="docflowDocTypes" onClick={this.toggleDocflowDocTypesList}>{this.context.t('Doc types')}</label>
                                        <span className={`expend-icon ${showDocflowDocTypes ? 'expended' : ''}`} onClick={this.toggleDocflowDocTypesList}></span>

                                        {
                                            showDocflowDocTypes ? (
                                                <ul>
                                                    {
                                                        AVAILABLE_DOC_TYPES.map(type => {
                                                            return (
                                                                <li className={`${docflowDocTypes.includes(t => t.docType === type.docType) ? 'selected' : ''} ${!this.checkIfCanSelectDocType(type.docType, false) ? 'cant-use' : ''}`} onClick={() => this.selectDocflowType(type)}>
                                                                    {this.context.t(type.label)}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            ) : null
                                        }
                                    </div>
                                    <div className="form-group">
                                        <TextareaAutosize type="text" name="docflowDescription" id="docflowDescription" value={docflowDescription} onChange={this.onInputChange} autoComplete="off" />
                                        <label htmlFor="docflowDescription">{this.context.t('Document flow description')}</label>
                                    </div>

                                    <div className="action-buttons">
                                        <div className="button reset" onClick={() => this.resetDocflowChanges()}>
                                            {this.context.t('Start over')}
                                        </div>
                                        <div className="button save" onClick={() => this.saveDocflow()}>
                                            {this.context.t('Save')}
                                        </div>
                                    </div>
                                </div>
                                <div className="docflow-section">
                                    <div className="docflow-area">
                                        <div className="docflow-element start const">
                                            Start
                                        </div>
                                        <span className="docflow-arrow"></span>

                                        <DropTarget
                                            name={"docflow"}
                                            containedItems={[...docflowSchema]}
                                            handleItemDrop={this.handleDrop}
                                            moveItem={this.sortDocflow}
                                            moveNewItem={this.addAndSort}
                                            removeAllPseudoElements={this.removeAllPseudoElements}
                                            startDraggingDocflowElement={this.startDraggingDocflowElement}
                                            editItemClick={this.editDocflowItemClick}
                                            removeItemClick={this.removeDocflowItemClick}
                                            translate={this.context.t}
                                        />

                                        <span className="docflow-arrow"></span>
                                        <div className="docflow-element end const">
                                            Koniec
                                        </div>
                                    </div>
                                    <div className="avaialble-stages">
                                        {
                                            AVAILABLE_STAGES.map((stage, index) => {
                                                return (
                                                    <DragItem
                                                        canBeUsed={this.checkIfStageCanBeUsed(stage.itemType)}
                                                        index={index}
                                                        key={`stage_${index}`}
                                                        item={stage}
                                                        listName={'STAGES'}
                                                        handleItemDrop={this.handleDrop}
                                                        selectedFields={[]}
                                                        translate={this.context.t}
                                                        moveNewItem={this.addAndSort}
                                                        removeAllPseudoElements={this.removeAllPseudoElements}
                                                        moveItem={() => { }}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="available-events">
                                        {
                                            AVAILABLE_EVENTS.map((event, index) => {
                                                return (
                                                    <DragItem
                                                        canBeUsed={true}
                                                        index={index}
                                                        key={`event_${index}`}
                                                        item={event}
                                                        listName={'EVENTS'}
                                                        handleItemDrop={this.handleDrop}
                                                        selectedFields={[]}
                                                        translate={this.context.t}
                                                        moveNewItem={this.addAndSort}
                                                        removeAllPseudoElements={this.removeAllPseudoElements}
                                                        moveItem={() => { }}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DndProvider>
            </div >
        )
    }
}

DocflowAssistant.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    userCompanies: state.Company.companies,
    userProjects: state.User.userProjects,
    currentProject: state.User.currentProject,
    projectDocflows: state.Docflow.projectDocflows,
})

const mapDispatchToProps = {
    getProjectMembersForDocflow: DocflowActions.getProjectMembersForDocflow,
    createNewDocflow: DocflowActions.createNewDocflow,
    saveDocflowChanges: DocflowActions.saveDocflowChanges,
    archiveDocflow: DocflowActions.archiveDocflow,
    getProjectDocflows: DocflowActions.getProjectDocflows,
    toggleQuickAction: UserActions.toggleQuickAction,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowAssistant)