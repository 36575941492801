import arrayObjectIndexOf from "../helpers/indexOfObject"
import moment from 'moment'

export const UserConst = {
  LOGGED_TRUE: 'LOGGED_TRUE',
  LOGGED_FALSE: 'LOGGED_FALSE',
  ASK_FOR_EASYGUARD: 'ASK_FOR_EASYGUARD',
  HIDE_ASK_FOR_EASYGUARD: 'HIDE_ASK_FOR_EASYGUARD',
  HIDE_FIRST_LOGIN_POPUP: 'HIDE_FIRST_LOGIN_POPUP',

  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  ACCEPTED_FIRST_DOCUMENT: 'ACCEPTED_FIRST_DOCUMENT',

  GET_ALL_USERS: 'GET_ALL_USERS',
  SORT_ALL_USERS: 'SORT_ALL_USERS',
  GET_USER_DETAILS: 'GET_USER_DETAILS',

  GET_ALL_PROJECTS: 'GET_ALL_PROJECTS',
  SORT_ALL_PROJECTS: 'SORT_ALL_PROJECTS',
  GET_FILE_COUNTS_FOR_PROJECT: 'GET_FILE_COUNTS_FOR_PROJECT',
  GET_PROJECT_DETAILS: 'GET_PROJECT_DETAILS',
  GET_USER_PROJECTS: 'GET_USER_PROJECTS',

  TOGGLE_FAVOURITE_FOR_PROJECT: 'TOGGLE_FAVOURITE_FOR_PROJECT',
  TOOGLE_NOTIFICATIONS_FOR_PROJECT: 'TOOGLE_NOTIFICATIONS_FOR_PROJECT',
  SWITCH_DASHBOARD_VIEW_MODEL: 'SWITCH_DASHBOARD_VIEW_MODEL',
  SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
  UPDATE_USER_LIMITS: 'UPDATE_USER_LIMITS',
  UPDATE_USER_BANDWIDTH_LIMITS: "UPDATE_USER_BANDWIDTH_LIMITS",

  SWITCH_FILE_MANAGER_VIEW_MODE: 'SWITCH_FILE_MANAGER_VIEW_MODE',

  TOGGLE_NOTIFY: 'TOGGLE_NOTIFY',

  SEND_TICKET_REQUEST: 'SEND_TICKET_REQUEST',
  GOT_TICKET_RESPONSE: 'GOT_TICKET_RESPONSE',

  CHANGE_MONTH_ON_DASHBOARD_CALENDAR: 'CHANGE_MONTH_ON_DASHBOARD_CALENDAR',
  TOOGLE_DAY_ON_DASHBOARD_CALENDAR: 'TOOGLE_DAY_ON_DASHBOARD_CALENDAR',

  LOAD_NEW_TAGS_FOR_COMPANY: 'LOAD_NEW_TAGS_FOR_COMPANY',

  DELETE_PROJECT: 'DELETE_PROJECT-USER',
  UPDATE_COMPANY_SUBSCRIPTION: 'UPDATE_COMPANY_SUBSCRIPTION',
  LOGOUT: 'LOGOUT',

  SET_OVERLAY_PREFERANCE: 'SET_OVERLAY_PREFERANCE',

  SET_WHATS_NEW_VERSION: 'SET_WHATS_NEW_VERSION',
  SENT_NEW_EMAIL_AS_SUPERADMIN: 'SENT_NEW_EMAIL_AS_SUPERADMIN',
  GET_ALL_EMAILS_AS_SUPERADMIN: 'GET_ALL_EMAILS_AS_SUPERADMIN',
  SORT_ALL_EMAILS_FOR_SUPERADMIN: 'SORT_ALL_EMAILS_FOR_SUPERADMIN',
  GET_WHATS_NEW_FOR_SUPERADMIN: 'GET_WHATS_NEW_FOR_SUPERADMIN',
  GET_ALL_ED_PAYMENTS_AS_SUPERADMIN: 'GET_ALL_ED_PAYMENTS_AS_SUPERADMIN',
  GET_DETAIL_PAYMENTS_AS_SUPERADMIN: 'GET_DETAIL_PAYMENTS_AS_SUPERADMIN',
  CLEAR_DETAIL_PAYMENTS_AS_SUPERADMIN: 'CLEAR_DETAIL_PAYMENTS_AS_SUPERADMIN',
  SORT_ALL_ED_PAYMENTS_FOR_SUPERADMIN: 'SORT_ALL_ED_PAYMENTS_FOR_SUPERADMIN',
  GET_ED_PAYMENTS_REPORT_FOR_SUPERADMIN: 'GET_ED_PAYMENTS_REPORT_FOR_SUPERADMIN',
  GET_MARKETING_REPORT_FOR_SUPERADMIN: 'GET_MARKETING_REPORT_FOR_SUPERADMIN',

  SEND_SET_NEW_PASSWORD_REQUEST: 'SEND_SET_NEW_PASSWORD_REQUEST',
  GOT_SET_NEW_PASSWORD_RESPONSE: 'GOT_SET_NEW_PASSWORD_RESPONSE',

  TOGGLE_2FA: 'TOGGLE_2FA',
  RESET_EASYGUARD_DEVICE: 'RESET_EASYGUARD_DEVICE',
  REGISTER_EASYGUARD_DEVICE: 'REGISTER_EASYGUARD_DEVICE'
}

const initialState = {
  user: null,
  logged: false, // undefined || true || false
  dashboardViewModel: 'MULTI',
  fileManagerViewMode: 'list',
  currentProject: {},
  monthOnToolbarCalendar: moment().format("'YYYY-MM'"),
  dayOnToolbarCalendar: null,

  sendSubmitTicketRequest: false,
  gotSubmitTicketResponse: false,
  submitTicketOk: false,
  reportsSearch: {},

  easydocsPaymentsReport: [],
  easydocsDetailPayments: undefined,
}

function dynamicComparer(key, order = 'asc') {
  return function (a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = (typeof a[key] === 'string') ?
      a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ?
      b[key].toUpperCase() : b[key];

    var comparison = 0;
    if (!varA) {
      comparison = -1;
    } else if (!varB) {
      comparison = 1;
    } else if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    if (key === 'net_value' || key === 'amount_decimal') {
      if (parseFloat(varA) > parseFloat(varB)) {
        comparison = 1;
      } else if (parseFloat(varA) < parseFloat(varB)) {
        comparison = -1;
      } else {
        comparison = -1;
      }
    }

    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

function userProjectsSort() {
  return function (a, b) {
    if (a.favourite) {
      if (b.favourite) {
        if (a.code.toUpperCase() > b.code.toUpperCase()) {
          return 1
        } else {
          return -1
        }
      } else {
        return -1
      }
    } else {
      if (b.favourite) {
        return 1
      } else {
        if (a.code.toUpperCase() > b.code.toUpperCase()) {
          return 1
        } else {
          return -1
        }
      }
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case UserConst.LOGGED_TRUE:
      return { ...state, ...{ user: action.user, logged: true, showEasyGuardPopup: false, firstLogin: action.firstLogin } }

    case UserConst.LOGGED_FALSE:
      return { ...state, ...{ user: null, logged: false } }

    case UserConst.ASK_FOR_EASYGUARD:
      return { ...state, ...{ showEasyGuardPopup: true } }
    case UserConst.HIDE_ASK_FOR_EASYGUARD:
      return { ...state, ...{ showEasyGuardPopup: false } }

    case UserConst.HIDE_FIRST_LOGIN_POPUP:
      return { ...state, ...{ firstLogin: false } }

    case UserConst.UPDATE_USER_PROFILE:
      return { ...state, ...{ user: { ...state.user, ...action.user } } }

    case UserConst.ACCEPTED_FIRST_DOCUMENT:
      return { ...state, ...{ acceptedFirtsDocument: true } }

    case UserConst.GET_ALL_USERS:
      return { ...state, ...{ users: action.users, usersSortField: 'created', usersSortOrder: 'desc' } }

    case UserConst.SORT_ALL_USERS:
      return { ...state, ...{ users: state.users.sort(dynamicComparer(action.field, action.order)), usersSortField: action.field, usersSortOrder: action.order } }

    case UserConst.GET_USER_DETAILS:
      return { ...state, ...{ investor: action.investor } }

    case UserConst.GET_ALL_PROJECTS:
      return { ...state, ...{ projects: action.projects, projectsSortField: 'created', projectsSortOrder: 'desc' } }

    case UserConst.SORT_ALL_PROJECTS:
      return { ...state, ...{ projects: state.projects.sort(dynamicComparer(action.field, action.order)), projectsSortField: action.field, projectsSortOrder: action.order } }

    case UserConst.GET_FILE_COUNTS_FOR_PROJECT:
      var updatedProjects = [...state.projects]
      var projectIndex = arrayObjectIndexOf(updatedProjects, action.project.id, 'id')
      if (projectIndex > -1) {
        updatedProjects[projectIndex] = action.project
      }
      return { ...state, ...{ projects: updatedProjects } }

    case UserConst.GET_PROJECT_DETAILS:
      return { ...state, ...{ projectDetails: action.project } }

    case UserConst.GET_USER_PROJECTS:
      var maxRole = 1
      var currentProject = state.currentProject
      action.userProjects.forEach(project => {
        if (project.acceptance_permissions === 'USER2' && maxRole < 2) {
          maxRole = 2
        } else if (project.acceptance_permissions === 'USER3' && maxRole < 3) {
          maxRole = 3
        }
      })

      if (action.userProjects.length === 1 || action.userProjects.length > 5) {
        if (localStorage.getItem('dashboardProject')) {
          var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject'))
          if (dashboardProjectId > -1) {
            projectIndex = arrayObjectIndexOf(action.userProjects, dashboardProjectId, 'id')
            if (projectIndex > -1 && !currentProject.id) {
              currentProject = action.userProjects[projectIndex]
            }
          }
        }
        return { ...state, ...{ userProjects: action.userProjects, groupedProjects: action.groupedProjects || [], dashboardViewModel: 'SINGLE', currentProject: currentProject.id ? currentProject : action.userProjects[0], highestAcceptancePermission: 'USER' + maxRole } }
      } else {
        return { ...state, ...{ userProjects: action.userProjects, groupedProjects: action.groupedProjects || [], highestAcceptancePermission: 'USER' + maxRole } }
      }

    case UserConst.TOGGLE_FAVOURITE_FOR_PROJECT:
      var userProjects = [...state.userProjects]

      var projectIndex = arrayObjectIndexOf(userProjects, action.projectId, 'id')

      if (projectIndex > -1) {
        userProjects[projectIndex].favourite = action.favourite
      }

      userProjects.sort(userProjectsSort())

      return { ...state, ...{ userProjects: userProjects } }

    case UserConst.TOOGLE_NOTIFICATIONS_FOR_PROJECT:
      var userProjects = [...state.userProjects]
      var projectIndex = arrayObjectIndexOf(userProjects, action.projectId, 'id')
      if (projectIndex > -1) {
        userProjects[projectIndex][action.notificationType] = action.newValue
      }

      return { ...state, ...{ userProjects: userProjects } }

    case UserConst.SWITCH_DASHBOARD_VIEW_MODEL:
      var newModel, currentProject = state.currentProject
      if (action.project) {
        currentProject = state.userProjects.find(e => e.id === action.project)
        newModel = 'SINGLE'
        localStorage.setItem('dashboardProject', action.project)
      } else {
        newModel = 'MULTI'
        currentProject = {}
        localStorage.setItem('dashboardProject', -1)
      }
      return { ...state, ...{ dashboardViewModel: newModel, currentProject: currentProject, monthOnToolbarCalendar: moment().format('YYYY-MM') }, dayOnToolbarCalendar: null }

    case UserConst.SET_CURRENT_PROJECT:
      const projects = state.userProjects
      var projectIndex = arrayObjectIndexOf(projects, action.project, "id");
      var projectToSet
      if (projectIndex > -1) {
        if (projects[projectIndex].id !== state.currentProject.id) {
          projectToSet = projects[projectIndex]
        } else {
          projectToSet = state.currentProject
        }
      }
      return { ...state, ...{ currentProject: projectToSet, monthOnToolbarCalendar: moment().format('YYYY-MM') }, dayOnToolbarCalendar: null }

    case UserConst.LOGOUT:
      return {
        ...initialState
      }

    case UserConst.TOGGLE_NOTIFY:
      var user = { ...state.user }

      if (action.notifyType === 'events') {
        user.notify_about_events = !user.notify_about_events
      } else if (action.notifyType === 'docs') {
        user.notify_about_docs = !user.notify_about_docs
      } else if (action.notifyType === 'payments') {
        user.notify_about_upcoming_payments = !user.notify_about_upcoming_payments
      } else if (action.notifyType === 'urgentDocs') {
        user.notify_about_urgent_docs = !user.notify_about_urgent_docs
      }

      return { ...state, ...{ user: user } }

    case UserConst.CHANGE_MONTH_ON_DASHBOARD_CALENDAR:
      return { ...state, ...{ monthOnToolbarCalendar: moment(action.date).format('YYYY-MM') }, dayOnToolbarCalendar: null }

    case UserConst.TOOGLE_DAY_ON_DASHBOARD_CALENDAR:
      if (!action.date) {
        return { ...state, ...{ dayOnToolbarCalendar: null } }
      } else {
        if (state.dayOnToolbarCalendar !== moment(action.date).format('DD-MM-YYYY')) {
          return { ...state, ...{ dayOnToolbarCalendar: moment(action.date).format('DD-MM-YYYY') } }
        } else {
          return { ...state, ...{ dayOnToolbarCalendar: null } }
        }
      }

    case UserConst.SEND_TICKET_REQUEST:
      return { ...state, ...{ sendSubmitTicketRequest: true, gotSubmitTicketResponse: false, submitTicketOk: false } }

    case UserConst.GOT_TICKET_RESPONSE:
      return { ...state, ...{ sendSubmitTicketRequest: false, gotSubmitTicketResponse: true, submitTicketOk: action.success } }

    // case UserConst.UPDATE_USER_LIMITS:
    //   var userProjects = [...state.userProjects]

    //   userProjects.forEach(project => {
    //     if (project.company_id === action.companyId) {
    //       project.userLimit.files_uploaded += 1
    //       project.userLimit.storage_used = action.action === 'UPLOAD' ? (project.userLimit.storage_used + action.size) : (project.userLimit.storage_used)
    //       project.userLimit.bandwith_used = project.userLimit.bandwith_used + action.size
    //     }
    //   })

    //   return { ...state, ...{ userProjects: userProjects } }

    // case UserConst.UPDATE_USER_BANDWIDTH_LIMITS:
    //   var userProjects = [...state.userProjects]

    //   userProjects.forEach(project => {
    //     if (project.company_id === action.companyId) {
    //       project.userLimit.bandwith_used = project.userLimit.bandwith_used + action.size
    //     }
    //   })

    //   return { ...state, ...{ userProjects: userProjects } }

    case UserConst.SWITCH_FILE_MANAGER_VIEW_MODE:
      return { ...state, ...{ fileManagerViewMode: action.mode } }

    case UserConst.DELETE_PROJECT:
      var { currentProject, dashboardViewModel } = state
      var userProjects = [...state.userProjects]

      userProjects = userProjects.filter(p => p.id !== action.projectId)
      var maxRole = 1
      userProjects.forEach(project => {
        if (project.acceptance_permissions === 'USER2' && maxRole < 2) {
          maxRole = 2
        } else if (project.acceptance_permissions === 'USER3' && maxRole < 3) {
          maxRole = 3
        }
      })

      if (currentProject && currentProject.id === action.projectId) {
        currentProject = {}
        dashboardViewModel = 'MULTI'
      }

      return { ...state, ...{ currentProject: currentProject, dashboardViewModel: dashboardViewModel, highestAcceptancePermission: 'USER' + maxRole, userProjects: userProjects } }

    case UserConst.UPDATE_COMPANY_SUBSCRIPTION:
      var currentProject = { ...state.currentProject };
      var userProjects = [...state.userProjects];

      if (currentProject && currentProject.company_id === action.companyId && currentProject.company) {
        currentProject.company.subscription_expire_date = action.subscriptionExpireDate;
        currentProject.company.payment_attempt_number = 0
        if (currentProject.company.subscription_type === "FREE") {
          currentProject.company.subscription_type = "ACTIVE";
        }
      }

      var projectsCount = userProjects.length;
      for (var i = 0; i < projectsCount; i++) {
        if (userProjects[i].company_id === action.companyId) {
          if (userProjects[i].company) {
            userProjects[i].company.subscription_expire_date = action.subscriptionExpireDate;
            userProjects[i].company.payment_attempt_number = 0
            if (userProjects[i].company.subscription_type === "FREE") {
              userProjects[i].company.subscription_type = "ACTIVE";
            }
          }
          break;
        }
      }
      return {
        ...state,
        ...{ currentProject: currentProject, userProjects: userProjects },
      };
    case UserConst.SET_OVERLAY_PREFERANCE:
      return { ...state, ...{ overlayPreferance: action.preferances } }

    case UserConst.LOAD_NEW_TAGS_FOR_COMPANY:
      var currentProject = { ...state.currentProject }
      var userProjects = state.userProjects ? [...state.userProjects] : []

      if (currentProject && currentProject.company_id === action.companyId && currentProject.company) {
        currentProject.company.tags_list = action.newTags
      }

      userProjects.forEach(project => {
        if (project && project.company_id === action.companyId && project.company) {
          project.company.tags_list = action.newTags
        }
      })

      return { ...state, ...{ currentProject: currentProject, userProjects: userProjects } }

    case UserConst.SET_WHATS_NEW_VERSION:
      return {
        ...state,
        user: {
          ...state.user,
          whatsNewVersion: action.version
        }
      }

    case UserConst.SENT_NEW_EMAIL_AS_SUPERADMIN:
      var emails = [...state.informationEmails]
      emails.push(action.email)
      return { ...state, ...{ informationEmails: emails.sort(dynamicComparer(state.informationEmailsSortField, state.informationEmailsSortOrder)) } }

    case UserConst.GET_ALL_EMAILS_AS_SUPERADMIN:
      return { ...state, ...{ informationEmails: action.emails.sort(dynamicComparer('created', 'desc')), informationEmailsSortField: 'created', informationEmailsSortOrder: 'desc' } }

    case UserConst.GET_WHATS_NEW_FOR_SUPERADMIN:
      return { ...state, ...{ whatsNewList: action.list } }

    case UserConst.SORT_ALL_EMAILS_FOR_SUPERADMIN:
      return { ...state, ...{ informationEmails: state.informationEmails.sort(dynamicComparer(action.field, action.order)), informationEmailsSortField: action.field, informationEmailsSortOrder: action.order } }

    case UserConst.GET_ALL_ED_PAYMENTS_AS_SUPERADMIN:
      return { ...state, ...{ easydocsPayments: action.payments.sort(dynamicComparer('created', 'desc')), easydocsPaymentsSortField: 'created', easydocsPaymentsSortOrder: 'desc' } }

    case UserConst.SORT_ALL_ED_PAYMENTS_FOR_SUPERADMIN:
      return { ...state, ...{ easydocsDetailPayments: state.easydocsDetailPayments.sort(dynamicComparer(action.field, action.order)), easydocsPaymentsSortField: action.field, easydocsPaymentsSortOrder: action.order } }

    case UserConst.GET_ED_PAYMENTS_REPORT_FOR_SUPERADMIN:
      return { ...state, ...{ easydocsPaymentsReport: action.report, easydocsPaymentsYearsReport: action.years, easydocsPaymentsTotal: action.total } }

    case UserConst.GET_DETAIL_PAYMENTS_AS_SUPERADMIN:
      return { ...state, ...{ easydocsDetailPayments: action.payments } }

    case UserConst.CLEAR_DETAIL_PAYMENTS_AS_SUPERADMIN:
      return { ...state, ...{ easydocsDetailPayments: undefined } }

    case UserConst.GET_MARKETING_REPORT_FOR_SUPERADMIN:
      return { ...state, ...{ easydocsMarketingReport: { lastWeek: action.weekData, currentMonth: action.monthData, currentYear: action.yearData } } }

    case UserConst.SEND_SET_NEW_PASSWORD_REQUEST:
      return { ...state, ...{ setPasswordButtonInactive: true } }
    case UserConst.GOT_SET_NEW_PASSWORD_RESPONSE:
      return { ...state, ...{ setPasswordButtonInactive: false } }

    case UserConst.TOGGLE_2FA:
      var user = { ...state.user }

      user.enabled_2fa = action.enabled

      return { ...state, ...{ user: user } }

    case UserConst.RESET_EASYGUARD_DEVICE:
      return { ...state, ...{ user: { ...state.user, has_registered_2fa_device: false } } }

    case UserConst.REGISTER_EASYGUARD_DEVICE:
      return { ...state, ...{ user: { ...state.user, has_registered_2fa_device: true, registered_2fa_device_name: action.deviceName } } }

    default:
      return state
  }
}

export const getCurrentUser = (state) => state.User.user;