import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Spinner, Bounce } from 'react-activity'
import TextareaAutosize from 'react-textarea-autosize'

import { AlertActions } from '../../actions/AlertActions'
import { UserActions } from '../../actions/UserActions';
import { CompanyActions } from '../../actions/CompanyActions';

import isIterable from "../../helpers/isIterable"
import { AVAILABLE_DOC_TYPES } from '../../helpers/docflowHelpers'


export class DocflowsTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedDocTypeId: 'ALL',
            selectedDocTypeLabel: 'All ',
            showBindersList: false,
            showDocTypesList: false
        }

        this.bindersListWrapperRef = React.createRef()
        this.docTypesWrapperRef = React.createRef()
    }

    componentDidMount() {
        const { projectDocflows, currentProject } = this.props
        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = event => {
        if (this.state.showBindersList && this.bindersListWrapperRef && !this.bindersListWrapperRef.current.contains(event.target)) {
            this.setState({
                showBindersList: false
            })
        } else if (this.state.showDocTypesList && this.docTypesWrapperRef && !this.docTypesWrapperRef.current.contains(event.target)) {
            this.setState({
                showDocTypesList: false
            })
        }
    }

    toggleBindersList = () => {
        const { showBindersList } = this.state
        this.setState({
            showBindersList: !showBindersList
        })
    }

    selectProject = binder => {
        const { availableDocFlowBinders, currentProject } = this.props
        if (currentProject.id !== binder.id && availableDocFlowBinders.find(b => b.id === binder.id)) {
            this.props.switchDashboardViewModel(binder.id)

            var { userCompanies, currentCompany, userProjects } = this.props
            const newProject = userProjects.find(p => p.id === binder.id)
            var companyId = newProject?.company_id || -1

            if (companyId !== -1 && currentCompany && currentCompany.id !== companyId) {
                var companyIndex = userCompanies.findIndex(c => c.company_id === companyId)
                if (companyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[companyIndex])
                }
            }

            this.setState({
                showBindersList: false
            })
        }
    }

    toggleDocTypesList = () => {
        const { showDocTypesList } = this.state
        this.setState({
            showDocTypesList: !showDocTypesList
        })
    }

    removeDocType = docType => {
        const { docTypes } = this.state
        var tempDocTypes = [...docTypes]

        tempDocTypes = tempDocTypes.filter(t => t.docType !== docType)

        this.setState({
            docTypes: tempDocTypes,
            showDocTypesList: false
        })
    }

    selectDocflowType = type => {
        this.setState({
            selectedDocTypeId: type.docType,
            selectedDocTypeLabel: type.label,
            showDocTypesList: false
        })
    }

    rederDocflowDocTypes = docTypes => {
        const { language } = this.props
        var elementsToReturn = []
        docTypes.forEach(type => {
            let typeShortName = ''
            if (['COSTS', 'EXPENSES_REPORT'].includes(type)) typeShortName = language === 'en' ? 'C' : 'K'
            else if (type === 'EXPENSES') typeShortName = language === 'en' ? 'E' : 'W'
            else if (type === 'PAYMENT_ORDER') typeShortName = language === 'en' ? 'PO' : 'ZP'
            else if (type === 'SALE_INVOICE') typeShortName = language === 'en' ? 'SI' : 'DP'
            else if (type === 'AGREEMENT') typeShortName = language === 'en' ? 'A' : 'U'
            else if (type === 'CORRESPONDENCE') typeShortName = language === 'en' ? 'CO' : 'KO'
            else if (type === 'CORPORATE') typeShortName = language === 'en' ? 'CR' : 'KR'
            else if (type === 'OTHER') typeShortName = language === 'en' ? 'O' : 'I'
            else if (type === 'WAREHOUSE') typeShortName = language === 'en' ? 'W' : 'DM'
            else if (type === 'PROTOCOL') typeShortName = language === 'en' ? 'P' : 'P'

            elementsToReturn.push(
                <span className={`type ${type.toLowerCase()}`}>
                    {typeShortName}
                </span>
            )
        })
        return elementsToReturn
    }

    renderDocflowScheme = docflowSteps => {
        var elementsToReturn = []
        docflowSteps.forEach((step, index) => {
            elementsToReturn.push(
                <div className={`step ${['CONDITION', 'NOTIFICATION'].includes(step.step_type) ? 'event' : 'stage'}`}>
                    {this.context.t(step.name)}
                </div>
            )

            if (index !== docflowSteps?.length - 1) {
                elementsToReturn.push(<span className="separator"></span>)
            }
        })

        return elementsToReturn
    }

    render() {
        const { showNoAdminMessage, availableDocFlowBinders, projectDocflows, currentProject } = this.props
        const {
            selectedDocTypeId, selectedDocTypeLabel,
            showBindersList, showDocTypesList,
        } = this.state

        return (
            <div className="docflow-table-wrapper">
                <div className="filters-wrapper">
                    <h3>{this.context.t('Your docflows')}</h3>
                    <div className={`form-group ${showBindersList ? 'in-front' : ''}`} ref={this.bindersListWrapperRef}>
                        <input type="text" name="" id="" value={currentProject.code} onClick={this.toggleBindersList} autoComplete="off" />
                        <label htmlFor="" onClick={this.toggleBindersList}>{this.context.t('Binder')}</label>
                        <span className={`expend-icon ${showBindersList ? 'expended' : ''}`} onClick={this.toggleBindersList}></span>

                        {
                            showBindersList ? (
                                <ul>
                                    {
                                        availableDocFlowBinders.map(b => {
                                            return (
                                                <li className={`${currentProject.id === b.id ? 'selected' : ''}`} onClick={() => this.selectProject(b)}>
                                                    {b.code}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            ) : null
                        }
                    </div>

                    <div className={`form-group ${showDocTypesList ? 'in-front' : ''}`} ref={this.docTypesWrapperRef}>
                        <input type="text" name="" id="" value={this.context.t(selectedDocTypeLabel)} onClick={this.toggleDocTypesList} autoComplete="off" />
                        <label htmlFor="docflowDocTypes" onClick={this.toggleDocTypesList}>{this.context.t('Doc type')}</label>
                        <span className={`expend-icon ${showDocTypesList ? 'expended' : ''}`} onClick={this.toggleDocTypesList}></span>

                        {
                            showDocTypesList ? (
                                <ul>
                                    <li className={`${selectedDocTypeId === 'ALL' ? 'selected' : ''}`} onClick={() => this.selectDocflowType({ docType: 'ALL', label: 'All ' })}>
                                        {this.context.t('All ')}
                                    </li>
                                    {
                                        AVAILABLE_DOC_TYPES.map(type => {
                                            return (
                                                <li className={`${selectedDocTypeId === type.docType ? 'selected' : ''}`} onClick={() => this.selectDocflowType(type)}>
                                                    {this.context.t(type.label)}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            ) : null
                        }
                    </div>
                </div>

                {
                    currentProject?.id && projectDocflows?.length ? (
                        projectDocflows.map(projectObject => {
                            return (
                                projectObject.projectId === currentProject?.id ? (
                                    projectObject.loading ? (
                                        <div className="activity-indicator">
                                            <Spinner size={30} speed={0.8} color={"#444444"} />
                                        </div>
                                    ) : (
                                        projectObject.docflows?.length === 0 ? (
                                            <div className="no-docflows">
                                                <div className="image"></div>
                                                <h3>{this.context.t('You don\'t have any docflows in this binder yet, create the first one below')}</h3>
                                            </div>
                                        ) : (
                                            <div className="table-wrapper">
                                                <table>
                                                    <tr className='table-row header-row'>
                                                        <th className="actions"></th>
                                                        {/* <th className="binder">

                                                        </th> */}
                                                        <th className="doc-types">
                                                            {this.context.t('Doc types')}
                                                        </th>
                                                        <th className="name">
                                                            {this.context.t('Docflow name')}
                                                        </th>
                                                        <th className="schema">
                                                            {this.context.t('Schema')}
                                                        </th>
                                                    </tr >
                                                    {
                                                        projectObject.docflows.map(d => {
                                                            if (selectedDocTypeId === 'ALL' || d.doc_types.includes(selectedDocTypeId)) {
                                                                return (
                                                                    <tr className='table-row'>
                                                                        <td className="actions">
                                                                            <div className="td-content-wrapper">
                                                                                {
                                                                                    d.archived ? (
                                                                                        <div className="archived">
                                                                                            {this.context.t('Archived')}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="button edit" onClick={() => this.props.editDocflow(d)}></div>
                                                                                            <div className="button copy" onClick={() => this.props.copyDocflowClick(d)}></div>
                                                                                            <div className="button delete" onClick={() => this.props.archiveDocflowClick(d)}></div>
                                                                                        </>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </td>
                                                                        <td className="doc-types">
                                                                            <div className="td-content-wrapper">
                                                                                {this.rederDocflowDocTypes(d.doc_types)}

                                                                            </div>
                                                                        </td>
                                                                        <td className="name">
                                                                            {this.context.t(d.name)}
                                                                        </td>
                                                                        <td className="schema">
                                                                            <div className="td-content-wrapper">
                                                                                {this.renderDocflowScheme(d.docflowSteps)}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        })
                                                    }
                                                </table >
                                            </div >
                                        )
                                    )
                                ) : null
                            )
                        })
                    ) : null
                }
            </div >
        )
    }
}

DocflowsTable.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    projectDocflows: isIterable(state.Docflow.projectDocflows) ? [...state.Docflow.projectDocflows] : [],

    currentProject: state.User.currentProject,
    userProjects: isIterable(state.User.userProjects) ? [...state.User.userProjects] : [],
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,

    language: state.i18nState.lang
})

const mapDispatchToProps = {
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowsTable)