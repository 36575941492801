import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Dots } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import TagsComponent from './TagsComponent';
import Calendar from '../Calendar';
import Calculator from '../Calculator';
import CountriesList from '../CountriesList';

import parseDate from '../../helpers/dateParser'
import isIterable from "../../helpers/isIterable"
import { checkIfAccountNumberIsValid } from '../../helpers/validAccountNumber';
import { vatIdValidate } from '../../helpers/vatIdValidate';


import '../../css/OcrPage.css'


import { FileActions } from '../../actions/FileActions';
import { CompanyActions } from '../../actions/CompanyActions';

const REQUIRED_FIELDS = ['issueDate', 'saleDate', 'buyerName', 'buyerVatId']
export class OcrPageCostsForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            buyerCountryCode: 'PL',
            showCountriesList: false
        }
    }

    getClassNameForOcrData = (propName, text, newCounterparty) => {
        const { paymentMethod, currency, paymentType, ocrMethod, dataReadWithOcrFrame } = this.props

        var classes = ''
        var hide = false
        var half = false

        if (paymentMethod !== 'not-paid') {
            if (propName === 'dueDate' || propName === 'bankData' || propName === 'price' || propName === 'tax' || propName === 'address' || propName === 'swift' || propName === 'countryCode') {
                hide = true
            }
        } else {
            if (propName === 'dueDate' || propName === 'issueDate') {
                half = true
            } else if (currency !== 'PLN') {
                if (propName === 'bankData' || propName === 'swift') {
                    half = true
                }
            } else if (currency === 'PLN' && (propName === 'address' || propName === 'swift' || propName === 'countryCode')) {
                hide = true
            }

            if (paymentType !== 'split' && propName === 'tax') {
                hide = true
            }
        }

        if (propName === 'brutto' || propName === 'netto') {
            half = true
        }

        if (newCounterparty) {
            if (propName === 'sellerName') {
                classes += ' show-seller-code'
            }
        } else {
            if (propName === 'sellerCode') {
                hide = true
            }
        }

        if (propName === 'sellerName' && ocrMethod === 'manual') {
            classes += ' counterparties-list-container'
        }

        if (hide) classes += ' hide'
        if (half) classes += ' half'
        if (dataReadWithOcrFrame.includes(propName)) classes += ' finished-ocr-indication'
        if (REQUIRED_FIELDS.includes(propName) && !text) classes += ' required-field'

        return classes.trim()
    }

    checkDate = (date) => {
        if (date && parseDate(date).match(new RegExp("[0-9]{2}-[0-9]{2}-[0-9]{4}", "i"))) {
            return true
        } else {
            return false
        }
    }

    checkIfVatIdIsValid = (vatId) => {
        if (vatId) {
            var countryCode = ''
            const firstTwoLetter = /^[A-Z]{2}/
            if (firstTwoLetter.test(vatId) && !vatId.startsWith('PL')) {
                countryCode = vatId.slice(0, 2)
            }

            const { returnedOCRData, ocrMethod, currency } = this.props

            if (currency !== 'PLN' && returnedOCRData && (returnedOCRData.props || returnedOCRData.manualProps)) {
                var sellerCountry = {}

                if (ocrMethod === 'select') {
                    sellerCountry = returnedOCRData.props.find(e => e.name === 'countryCode')
                    if (sellerCountry && sellerCountry.text) {
                        countryCode = sellerCountry.text
                    }
                } else {
                    sellerCountry = returnedOCRData.manualProps.find(e => e.name === 'countryCode')
                    if (sellerCountry && sellerCountry.text) {
                        countryCode = sellerCountry.text
                    }
                }
                if (firstTwoLetter.test(vatId) && vatId.substring(0, 2) !== countryCode) return false
                if (!firstTwoLetter.test(vatId) && countryCode === 'DE') vatId = countryCode + vatId
            }
            return vatIdValidate(vatId)

        } else return true
    }

    checkIfAmountToPayIsValid = (amountToPay, otherValues) => {
        if (amountToPay) {
            if (/\.[0-9]{2}/.test(amountToPay) || /\,[0-9]{2}/.test(amountToPay)) {
                var bruttoValue = otherValues.find(e => e.name === 'brutto')
                if (bruttoValue && bruttoValue.text) {
                    if (/\.[0-9]{2}/.test(bruttoValue.text) || /\,[0-9]{2}/.test(bruttoValue.text)) {
                        bruttoValue.text = bruttoValue.text.replace(',', '.')
                        if (parseFloat(amountToPay) > parseFloat(bruttoValue.text)) {
                            return false
                        } else {
                            return true
                        }
                    } else return true
                } else return true
            } else {
                return false
            }
        } else return true
    }

    invalidAmountToPayMessage = (amountToPay, otherValues) => {
        if (amountToPay) {
            var bruttoValue = otherValues.find(e => e.name === 'brutto')
            if (bruttoValue && bruttoValue.text) {
                bruttoValue.text = bruttoValue.text.replace(',', '.')
                if (parseFloat(amountToPay) > parseFloat(bruttoValue.text)) {
                    return '*Amount to pay is greater than the gross amount, please make sure it is correct'
                } else {
                    return ''
                }
            } else return ''
        } else return ''
    }

    checkIfBruttoIsValid = (brutto, otherValues) => {
        if (brutto) {
            if (!/\.[0-9]{2}/.test(brutto) && !/\,[0-9]{2}/.test(brutto)) {
                return false
            } else if (this.props.currency === 'PLN') {
                var nettoValue = otherValues.find(e => e.name === 'netto')
                if (nettoValue && nettoValue.text) {
                    if (/\.[0-9]{2}/.test(nettoValue.text) || /\,[0-9]{2}/.test(nettoValue.text)) {
                        nettoValue.text = nettoValue.text.replace(',', '.')
                        if (parseFloat(brutto) > parseFloat(nettoValue.text) * 1.23 + 0.1) {
                            return false
                        } else {
                            return true
                        }
                    } else return true
                } else return true
            } else return true
        } else return true
    }

    invalidBruttoMessage = (brutto, otherValues) => {
        if (brutto) {
            if (this.props.currency === 'PLN') {
                var nettoValue = otherValues.find(e => e.name === 'netto')
                if (nettoValue && nettoValue.text) {
                    nettoValue.text = nettoValue.text.replace(',', '.')
                    if (parseFloat(brutto) > parseFloat(nettoValue.text) * 1.23 + 0.1) {
                        return '*The gross amount is greater than the net amount increased by 23% VAT, make sure it is correct'
                    } else {
                        return ''
                    }
                } else return ''
            } else return ''
        } else return ''
    }

    polihAccountPritier = account => {
        if (account) {
            var formatedAccount = account.replace(/[^0-9]/gm, '')

            if (formatedAccount.length === 26) {
                formatedAccount = formatedAccount.slice(0, 2) + ' ' + formatedAccount.slice(2, 6) + ' ' + formatedAccount.slice(6, 10) + ' ' + formatedAccount.slice(10, 14) + ' ' + formatedAccount.slice(14, 18) + ' ' + formatedAccount.slice(18, 22) + ' ' + formatedAccount.slice(22, 26)
                if (account.startsWith('PL')) {
                    return 'PL' + formatedAccount
                } else {
                    return formatedAccount
                }
            } else {
                return account
            }
        } else return ''
    }

    foundAtLeastOneSimilarCounterparty = (text) => {
        const { counterpartiesList } = this.props
        var counterpartiesCount = counterpartiesList.length

        if (!text) {
            return false
        }

        for (var i = 0; i < counterpartiesCount; i++) {
            if (text.length === 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())) {
                return true
            } else if (text.length > 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())) {
                return true
            }
        }

        return false
    }

    checkIfCounterpartyNameIsSimilar = (text, name) => {
        if (!text) {
            return false
        }

        if (text.length === 1 && name && name.toLowerCase().startsWith(text.toLowerCase())) {
            return true
        } else if (text.length > 1 && name && name.toLowerCase().includes(text.toLowerCase())) {
            return true
        }

        return false
    }

    blurTextArea = (e, propName, dbId) => {
        this.props.focusOutArea(e, dbId)
    }

    focusTextArea = (e, propName) => {
    }

    setSellerCountryCode = (value) => {
        this.props.setSellerCountryCode(value, 'countryCode', this.props.ocrMethod, this.props.currentInvoiceImageData.dbId)
    }

    getPropTitle = name => {
        if (name === 'invoiceNumber') {
            return this.context.t('Invoice Number')
        } else if (name === 'sellerVatId') {
            return this.context.t('Seller VAT-ID')
        } else if (name === 'sellerName') {
            return this.context.t('Seller Name')
        } else if (name === 'buyerVatId') {
            return this.context.t('Buyer VAT-ID')
        } else if (name === 'buyerName') {
            return this.context.t('Buyer Name')
        } else if (name === 'saleDate') {
            return this.context.t('Sale date')
        } else if (name === 'issueDate') {
            return this.context.t('Issue date')
        } else if (name === 'dueDate') {
            return this.context.t('Due date')
        } else if (name === 'netto') {
            return this.context.t('Net value')
        } else if (name === 'brutto') {
            return this.context.t('Gross value')
        } else if (name === 'accountNumber') {
            return this.context.t('Account number')
        }
    }

    render() {
        const { currentInvoiceImageData, currentProjectRole, paymentMethod, comment, tags, ocrMethod, calcHeight, returnedOCRData, processedData, acceptedAmounttoPay, focusedTextArea, posUpDatePicker, posYDatePicker, showAccountNumberWarningBeforeSave, showTagsList, tagsList, tagsCreationLimitation, dataConfirmCheckbox, counterpartiesList, showCounterpartiesList, posYCalculator, posUpCalculator, user, docDescription, cashAccountingScheme, isCorrection, assignToAgreement, isAccountantInCurrentCompany, selectBayerCountryHandler, buyerCountryCode, showCountriesList, toggleCountriesList, processingData, documentDescription } = this.props


        return (
            <div id="invoice-content" ref="sideInvoiceBar">
                <div className="fixed-height-wrapper" style={{ height: calcHeight }} ref={this.props.setFormRef}>
                    <div className="invoice-content income-doc">
                        <SimpleBar style={{ height: calcHeight, width: 'calc(100% + 20px)', marginLeft: '-10px', paddingLeft: '10px', marginRight: '-10px', paddingRight: '10px' }}>
                            {
                                returnedOCRData && returnedOCRData.incomeProps ? (
                                    <>{
                                        returnedOCRData.incomeProps.map((prop, i) => {
                                            if (prop.name && prop.name !== 'docItems') {
                                                return (
                                                    <div className={`ocr-data ${prop.name} ${prop.name === 'sellerVatId' ? 'max' : ''} ${prop.name === 'buyerVatId' ? 'gapRight' : ''} ${this.getClassNameForOcrData(prop.name, prop.text, returnedOCRData.newCounterparty)}`} key={i} ref={prop.name + "PropRef"} onClick={e => this.props.showDatePicker(e, prop.name, prop.possition)}>
                                                        <label className={prop.name} onClick={(e) => this.props.scrollToArea(e, prop.name, prop.possition)}>
                                                            {this.getPropTitle(prop.name)}
                                                            {
                                                                processingData && processingData.find(e => e === prop.name) ? (
                                                                    <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                                                ) : null
                                                            }
                                                        </label>
                                                        <TextareaAutosize
                                                            key={`prop-field-${prop.name}`}
                                                            id={`prop-field-${prop.name}`}
                                                            className={`${prop.name} ${!acceptedAmounttoPay && prop.name === 'price' ? 'price-gray' : ''} ${focusedTextArea === prop.name ? 'focused' : ''}`}
                                                            value={prop.text}
                                                            onChange={(e) => this.props.onInputChange(e, currentInvoiceImageData.dbId, prop.name)}
                                                            onClick={(e) => this.props.scrollToArea(e, prop.name, prop.possition)}
                                                            onBlur={e => { this.blurTextArea(e, prop.name, currentInvoiceImageData.dbId); this.props.autoSaveOcr(currentInvoiceImageData.ftvId, prop.name, prop.text, 'income') }}
                                                            onFocus={e => this.focusTextArea(e, prop.name)}
                                                            name={prop.name}
                                                            maxRows={10}
                                                        />
                                                        {
                                                            prop.name === 'dueDate' || prop.name === 'issueDate' || prop.name === 'saleDate' ? (
                                                                <span className="calendar-icon" onClick={(e) => this.props.scrollToArea(e, prop.name, prop.possition)}></span>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            (prop.name === 'dueDate' && focusedTextArea === 'dueDate') || (prop.name === 'issueDate' && focusedTextArea === 'issueDate') || (prop.name === 'saleDate' && focusedTextArea === 'saleDate') ? (
                                                                <div className={`date-picker ${prop.text ? 'move-up' : ''} ${posUpDatePicker ? 'up' : ''}`} style={{ top: posYDatePicker }}>
                                                                    <Calendar
                                                                        selectDay={this.props.onCalendarSelect}
                                                                        unselectDay={this.props.onCalendarUnselecet}
                                                                        selectedDay={
                                                                            this.checkDate(prop.text) ? {
                                                                                display: moment(parseDate(prop.text), 'DD-MM-YYYY').format('LL'),
                                                                                value: moment(prop.text, 'DD-MM-YYYY')
                                                                            } : {
                                                                                display: null,
                                                                                value: null
                                                                            }
                                                                        }
                                                                        projectId={'none'}
                                                                        startDate={this.checkDate(prop.text) ? moment(parseDate(prop.text), 'DD-MM-YYYY') : moment()}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            (prop.name === 'netto' || prop.name === 'brutto') ? (
                                                                <>
                                                                    <span className="currency-label income">{this.props.currency}</span>
                                                                    {/* <span className="calculator-icon" onClick={(e) => this.props.showCalculator(e, prop.name)}></span> */}
                                                                </>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {/* {
                                                            ((prop.name === 'netto' && focusedTextArea === 'netto') || (prop.name === 'brutto' && focusedTextArea === 'brutto') || (prop.name === 'price' && focusedTextArea === 'price')) ? (
                                                                <Calculator returnValue={this.props.setAmountValue} propName={prop.name} ocrMethod={ocrMethod} fileId={currentInvoiceImageData.dbId} posYCalculator={posYCalculator} posUpCalculator={posUpCalculator}/>
                                                            ) : (
                                                                null
                                                            )
                                                        } */}
                                                        {
                                                            prop.name === 'buyerVatId' && prop.text && !this.checkIfVatIdIsValid(prop.text) ? (
                                                                <div className="incorrect-account-number" ref="incorrescAccountNumberRef">
                                                                    {this.context.t('*This VAT ID is not valid. The correctness of the VAT ID for numbers from Poland is checked on the basis of a checksum')}
                                                                </div>

                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            prop.name === 'accountNumber' && prop.text && !checkIfAccountNumberIsValid(prop.text) ? (
                                                                <div className="incorrect-account-number" ref="incorrescAccountNumberRef">
                                                                    {this.context.t('The above account number is incompatible with the IBAN standard.')}
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }

                                                        {
                                                            prop.name === 'brutto' && prop.text && !this.checkIfBruttoIsValid(prop.text, returnedOCRData.props) ? (
                                                                <div className="incorrect-account-number">
                                                                    <p>{this.context.t(this.invalidBruttoMessage(prop.text, returnedOCRData.props))}</p>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }

                                                        {
                                                            showAccountNumberWarningBeforeSave && prop.name === 'accountNumber' && prop.text && !checkIfAccountNumberIsValid(prop.text) ? (
                                                                <div className="incorrect-account-number-save-warning">
                                                                    <div className="close" onClick={() => this.props.hideAccountNumberWarning()}></div>
                                                                    <div className="text">
                                                                        {this.context.t('The above account number is incompatible with the IBAN standard.')}
                                                                    </div>
                                                                    <div className="save" onClick={() => this.props.saveData(true)}>
                                                                        {this.context.t('Ignore and Save')}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                    </div>
                                                )
                                            } else {
                                                return
                                            }
                                        })
                                    }
                                        <div className='ocr-data  BuyerCountryCode'>
                                            <label className='label-countryCode'>
                                                {this.context.t('Buyer Country Code')}
                                            </label>
                                            <TextareaAutosize
                                                className={'countryCode'}
                                                value={buyerCountryCode}
                                                maxRows={1}
                                                onClick={() => toggleCountriesList()}
                                            />
                                            {
                                                showCountriesList ? (
                                                    <CountriesList
                                                        headerText={this.context.t('Country:')}
                                                        selectedOption={buyerCountryCode}
                                                        select={selectBayerCountryHandler}
                                                        readOnly={true}
                                                    />
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>
                                    </>
                                ) : (
                                    null
                                )
                            }
                            <div className={`ocr-data`}>
                                <label>{this.context.t('Comment')}</label>
                                <TextareaAutosize
                                    className={``}
                                    value={comment}
                                    name='comment'
                                    onChange={(e) => this.props.setComment(e)}
                                    maxRows={10}
                                    onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'comment', comment, '')}
                                />
                            </div>
                            <div className={`ocr-data`}>
                                <label>{this.context.t('Document description')}</label>
                                <TextareaAutosize
                                    className={``}
                                    value={documentDescription}
                                    name='doc_description'
                                    onChange={(e) => this.props.setDocComment(e)}
                                    maxRows={10}
                                    onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', documentDescription, '')}
                                />
                            </div>
                            <TagsComponent
                                currentTags={tags}
                                tagsList={tagsList}
                                canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                                setTags={this.props.setTags}
                            />
                        </SimpleBar>
                    </div>
                </div>

                <div className="action-buttons" ref={this.props.setButtonsRef}>
                    {
                        currentProjectRole !== 'USER1' ? (
                            <div className="checkmark" onClick={() => this.props.confirmData()}>
                                {this.context.t('I confirm the compliance of the data in the form with the data on the original document')}
                                <div className={`checkbox ${dataConfirmCheckbox ? 'checked' : ''}`}></div>
                            </div>
                        ) : (
                            null
                        )
                    }
                    <button type="button" className={`button`} onClick={() => this.props.saveData()}>
                        {
                            currentInvoiceImageData.userCanVerifyAndAccept ?
                                this.context.t("Accept and Save")
                                :
                                currentInvoiceImageData.userAlreadyVerified ?
                                    this.context.t("Save")
                                    :
                                    this.context.t("Send for approval")

                        }
                    </button>
                </div>
            </div>
        )
    }
}

OcrPageCostsForm.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,

    returnedOCRData: { ...state.File.returnedOCRData },
    processingData: isIterable(state.File.processingData) ? [...state.File.processingData] : [],
    processedData: isIterable(state.File.processedData) ? [...state.File.processedData] : [],
    dataReadWithOcrFrame: isIterable(state.File.dataReadWithOcrFrame) ? [...state.File.dataReadWithOcrFrame] : []
})

const mapDispatchToProps = {
    autoSaveOcr: FileActions.autoSaveOcr,
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageCostsForm)
