import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import { Spinner } from 'react-activity'

import { AlertActions } from '../../actions/AlertActions'
import { DocflowActions } from '../../actions/DocflowActions'

export class DocflowStageEditWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            alreadyPassedSteps: [],
            currentStep: -1,
            showAcceptances: false
        }

        this.wrapperRef = React.createRef()
    }

    componentDidMount() {
        const { docflow, fileInstance } = this.props
        if (fileInstance) {
            var docflowOrder = docflow.docflow_scheme.stages_order
            if (docflowOrder) {
                var tempPassedSteps = [], foundCurrent = false
                docflowOrder.forEach((step, index) => {
                    if (step === fileInstance.docflow_step_id) {
                        foundCurrent = true
                    }

                    if (!foundCurrent) {
                        tempPassedSteps.push(step)
                    }
                })

                this.setState({
                    alreadyPassedSteps: tempPassedSteps,
                    currentStep: fileInstance.docflow_step_id,
                    showAcceptances: true
                })
            }
        }
        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.close()
        }
    }

    renderStepUsers = (stepType, requiredUsersCount, additionalSettings) => {
        const { fileInstance } = this.props
        var usersToReturn = []
        if (stepType === "CONDITION") {
            additionalSettings.conditionBlocks.forEach((block, index) => {
                if (block.selectedUsers) {
                    usersToReturn.push(
                        <div className="contition-header">{this.context.t(block.efectId === 'ACCEPTANCE' ? 'Acceptance' : 'Notification')}</div>
                    )
                    let renderedUsers = []
                    block.selectedUsers.forEach(user => {
                        if (user.id === 0 && fileInstance && fileInstance.uploaded_by_number) {
                            user.id = fileInstance.uploaded_by_number
                            user.name = fileInstance.uploadedBy
                        }
                        if (!renderedUsers.includes(user.id)) {
                            renderedUsers.push(user.id)
                            usersToReturn.push(
                                <div className="user">
                                    <div className="default-photo"></div>
                                    <div className="name">{user.name}</div>
                                </div>
                            )
                        }
                    })

                    if (additionalSettings.conditionBlocks[index + 1]) {
                        usersToReturn.push(
                            <div className="contition-separator">{this.context.t('Or')}</div>
                        )
                    }

                }
            })
        } else {
            let renderedUsers = []
            additionalSettings.selectedUsers.forEach(user => {
                if (user.id === 0 && fileInstance && fileInstance.uploaded_by_number) {
                    user.id = fileInstance.uploaded_by_number
                    user.name = fileInstance.uploadedBy
                }
                if (!renderedUsers.includes(user.id)) {
                    renderedUsers.push(user.id)
                    usersToReturn.push(
                        <div className="user">
                            <div className="default-photo"></div>
                            <div className="name">{user.name}</div>
                        </div>
                    )
                }
            })

            if (additionalSettings.selectedUsers.length > 1) {
                usersToReturn.push(
                    <div className="users-required">{this.context.t('required: {n1} from {n2}', { n1: requiredUsersCount, n2: usersToReturn.length })}</div>
                )
            }
        }

        return usersToReturn
    }

    render() {
        const { docflow, additionalVerification, additionalAcceptance, fileInstance } = this.props
        const {
            alreadyPassedSteps, currentStep, showAcceptances
        } = this.state

        return (
            <div className="popup docflow-info-popup">
                <div className="popup-card" ref={this.wrapperRef} style={{ width: `${docflow?.docflowSteps?.length * 200 + 20}px` }}>
                    <h3>{this.context.t('Scheme: {d}', { d: docflow.name })}</h3>
                    <div className="steps-wrapper">
                        {
                            docflow?.docflowSteps?.map((step, index) => {
                                console.log('HERE')
                                console.log(index < docflow?.docflowSteps.length - 1)
                                return (
                                    <>
                                        <div key={step.id} className={`step`}>
                                            <div className={`step-title ${alreadyPassedSteps.includes(step.id) ? 'passed' : ''} ${step.id === currentStep ? 'current' : ''}`}>{step.name}</div>
                                            <div className="step-users">
                                                {this.renderStepUsers(step.step_type, step.required_users_count, step.additional_settings)}
                                            </div>
                                        </div>

                                        {
                                            index < docflow?.docflowSteps.length - 1 ? (
                                                <div className="step-separator"></div>
                                            ) : null
                                        }
                                    </>
                                )
                            })
                        }
                    </div>

                    <div className="close-button" onClick={() => this.props.close()}>
                    </div>
                </div>
            </div>
        )

    }
}

DocflowStageEditWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowStageEditWindow)